import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import TierCard from 'pages/retail/components/TierCard'

const tiers = [
    {
        title: 'startz',
        pricing: 'FREE',
        content1: 'Help consumers find your store and the product categories you sell',
        content2: ` <br/><br/> `,
        keypoints: [
            'Set up your store profile',
            'Share categories',
            'Display hours & location',
            'Display logo, website link',
            'No credit card required',
            // '',
            // ''
        ],
        button: 'Get Free',
    },
    {
        title: 'sellz',
        pricing: '$99/mo.',
        content1: 'Increase your sales with the highest level of convenience for your customers.',
        content2: `Integrate your website or POS system easily.`,
        keypoints: [
            'Business profile on Fitzba',
            // 'Gift Card button',
            'Showcase your inventory',
            'Checkout and Reserve button',
            'Special Offers and Promoted items',
            // 'Feature in Shopping Guides',
            'Dashboard & Reporting',
        ],
        button: 'Get Sellz',
    },
]

export default function RegisterStep0({
    setCurrent,
    isMobile
}) {
    const [searchParams] = useSearchParams()

    // const [plan, setPlan] = useState('')

    useEffect(() => {
        if (searchParams.has('plan')) {

            const plan = searchParams.get('plan')
            console.log('plan', plan)
            localStorage.setItem('plan', plan)
            // setPlan(plan)
            const findPlan = tiers.find(tier => tier.title === plan)
            if (findPlan) {
                setCurrent(1)
            }
        }
    }, [searchParams])


    function onSelectPlan(plan) {
        localStorage.setItem('plan', plan)
        // setPlan(plan)
        setCurrent(1)
        console.log('plan', plan)
    }

    return (
        <div className='py-4 m-auto w-[1024px] smsize:w-full flex flex-col items-center gap-5'>
            <h1 className='text-[32px] font-medium text-center smsize:text-[24px] m-0'>Choose your plan</h1>
            <p>
                <Link to='/for-business' className='text-[#69BDC8] underline'>Learn more about each plan</Link>
            </p>
            <div className='flex items-start justify-center gap-10 smsize:flex-col'>
                {tiers.map((tier, index) => {
                    return (
                        <TierCard
                            key={index}
                            tier={tier}
                            isMobile={isMobile}
                            onSelectPlan={onSelectPlan}
                        />
                    )
                })}
            </div>
        </div>
    )
}
