/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Howitworks is a functional React component that displays a section explaining how the service works in three steps.
 * It includes two sets of descriptions: one for desktop view and one for mobile view.
 * Each description includes a title, a description, and an image.
 * The layout of the section changes based on the screen size.
 *
 * @author Yang Ming, James Nguyen, Joshua Soke
 * @version September 13th, 2023
 */

import React from 'react'
import RetailButtons from './RetailButtons'

const DescList = [
    {
        id: 1,
        title: 'Book a call',
        desc: `This is a free, no pressure call. <br/>We'll explain how it works.`,
        image: '/images/retail/works1.png',
        imageW: 130,
        imageH: 160
    },
    {
        id: 2,
        title: '5-minute Setup',
        desc: 'Our White Glove setup service means<br/>we do all the work for you.',
        image: '/images/retail/works2.png',
        imageW: 150,
        imageH: 150
    },
    {
        id: 3,
        title: 'Watch your sales grow',
        desc: 'With everying seamlessly connected,<br/>start exploring and utilizing the full<br/>potential of Fitzba.',
        image: '/images/retail/works3.png',
        imageW: 150,
        imageH: 150
    }
]

/**
 * Displays a section explaining how the service works in three steps.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isMobile - Whether the screen size is mobile or not.
 * @param {function} props.setIsOpen - Function to set the state of the component's open status.
 * @param {function} props.setIsOpenForm - Function to set the state of the form's open status.
 *
 * @returns {JSX.Element} The rendered component.
 */

const DescListMobile = [
    {
        id: 1,
        title: 'Book a call',
        desc: `This is a free, no pressure call. <br/>We'll explain how it works.`,
        image: '/images/retail/works1.png',
        imageW: 140,
        imageH: 240
    },
    {
        id: 2,
        title: '5-minute Setup',
        desc: 'Our White Glove setup service means<br/>we do all the work for you.',
        image: '/images/retail/works2.png',
        imageW: 180,
        imageH: 180
    },
    {
        id: 3,
        title: 'Watch your sales grow',
        desc: 'With everying seamlessly connected,<br/>start exploring and utilizing the full<br/>potential of Fitzba.',
        image: '/images/retail/works3.png',
        imageW: 160,
        imageH: 160
    }
]



export default function Howitworks({
    isMobile,
    setIsOpen,
    setIsOpenForm
}) {
    const list = isMobile ? DescListMobile : DescList
    return (
        <div className='w-full py-10 pb-40 border-t border-black smsize:pb-20'>
            <div className='w-full'>
                <h3 className='text-[45px] font-medium smsize:text-[45px] smsize:text-left smsize:pl-[30px]'>How it works. {' '}
                    <span className='font-light text-[#59B1B5] smsize:block'>3 easy steps</span>
                </h3>
                <div className='flex items-center justify-center py-10 flex-col smsize:gap-10 smsize:py-10 smsize:px-3 mt-3'>
                    {list.map((item, index) => (
                        <div className='w-full flex items-center justify-center smsize:flex-col smsize:px-5 smsize:gap-5' key={item.id}>
                            <div key={index} className='flex-[1] flex items-end justify-end flex-col pr-16 smsize:flex-row smsize:gap-3 smsize:items-center smsize:justify-start smsize:w-full smsize:pr-0'>
                                <div className='w-[53px] h-[53px] smsize:w-[40px] smsize:h-[40px] smsize:border smsize:text-[22px] text-[27px] font-medium flex items-center justify-center rounded-full border-2 border-black'>
                                    {item.id}
                                </div>
                                <div className='smsize:w-fit'>
                                    <h4 className='text-[23px] mt-5 font-medium text-right smsize:text-[18px] smsize:text-left smsize:mb-3 smsize:whitespace-nowrap'>{item.title}</h4>
                                    <p className='text-[16px] mt-2 font-light w-full text-right smsize:text-[16px] smsize:text-left smsize:leading-5' dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                                </div>
                            </div>
                            <div className='text-left flex-[1]  border-l pl-16  border-black w-fit pb-5 smsize:border-l-0 smsize:pl-0'>
                                <div className='w-[195px] mt-10 flex items-center justify-center'>
                                    <img src={item.image} width={item.imageW} height={item.imageH} alt={item.title} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='w-fit m-auto flex items-center justify-center smsize:w-full smsize:px-5'>
                    <RetailButtons
                        gap={isMobile ? '20px' : '60px'}
                        setIsOpen={setIsOpen}
                        isOpposite={isMobile}
                        setIsOpenForm={setIsOpenForm}
                        extraClass='smsize:px-3 mt-8'
                    />
                </div>
            </div>
        </div>
    )
}
