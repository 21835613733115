import React, { useState, useEffect } from 'react'
// import { getGlobalCategoryTopHeaderListNew } from '@services/api.globalCategory.service'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterCategoryList } from '@reducers/contentReducer'
import { Dropdown } from 'antd'
import { getStoreCategoryList, getGlobalCategoryList } from '@services/api.business.service'

export default function StoreCategoriesHeader({
  storeProfileId
}) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const storeProfileId = useSelector(state => state.content.storeProfileId)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    async function fetchData() {
      let res = await getStoreCategoryList({
        business_id: storeProfileId
      })
      if (res.data.length === 0) {
        res = await getGlobalCategoryList()
      }
      console.log('StoreCategoriesHeader list:', res)
      setCategories(res.data || [])
    }
    fetchData()
  }, [storeProfileId])

  function onClickCategories(id) {
    dispatch(setFilterCategoryList([id]))
  }

  if (!categories || categories.length === 0) return null

  console.log('StoreCategoriesHeader here is running')

  return (

    // sticky
    <div className='phone:gap-[40px] phone:justify-center less1400:overflow-x-scroll less1400:justify-center less1400:gap-[20px] flex h-[50px] phone:h-[45px] w-full flex-row items-center justify-start overflow-x-auto gap-3 overflow-y-hidden bg-[#454545] px-[20px] border-t-2 border-[#C8C8C8] smsize:!justify-start smsize:px-0'>
      {categories?.slice(0, 10)?.map((category) => (
        <Dropdown
          key={category?._id}
          overlayStyle={{
            textAlign: 'left',
          }}
          menu={{
            items: category?.children?.map((item) => ({
              key: item?._id,
              label: (
                <span onClick={() => onClickCategories(item?._id)}>
                  {item?.title}
                </span>
              ),
            }))
          }}
        >
          <span
            className='cursor-pointer whitespace-nowrap h-full flex items-center justify-center text-white hover:text-[#f3f3f3] px-5 sm:px-2 font-light text-[13px] font-lexend color-black hover:scale-105 transition-transform duration-150 ease-in-out'
            key={category?._id}
            onClick={() => {
              onClickCategories(category?._id)
            }}
          >
            {category?.title}
          </span>
        </Dropdown>
      ))}
    </div>
  )
}

