/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideSelectedMobile.js implements functionality for displaying selected filter options at the top of the screen
 * For mobile devices 
 * 
 * @author Yang Ming
 * @version February 12th, 2024 
 */

import React from 'react'
import MobileTopFilterPrice from './MobileTopFilterPrice'
import MobileTopFilterOnsale from './MobileTopFilterOnsale'
import MobileTopFilterCondition from './MobileTopFilterCondition'
import MobileTopFilterDistance from './MobileTopFilterDistance'
import MobileTopFilterBrand from './MobileTopFilterBrand'
import MobileTopFilterStore from './MobileTopFilterStore'
import MobileTopFilterReserve from './MobileTopFilterReserve'
import { useDispatch } from 'react-redux'
import { setSearchInput } from '@reducers/contentReducer'
import { initItemsFilter } from '@reducers/contentReducer'
import { useNavigate } from 'react-router-dom'
import MobileTopFilterAll from './MobileTopFilterAll'
import '../Zearch.css'
import MobileTopFilterCategory from './MobileTopFilterCategory'

export default function MobileTopFilter({
  isStoreProfile = false,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onClearAll = () => {
    dispatch(setSearchInput(''))
    dispatch(initItemsFilter(null))
    navigate('/search')
  }

  console.log('MobileTopFilter1111')

  return (
    <>
      <div className={`relative filter-top-mobile flex w-full`}>
        <div className='w-full flex gap-2 flex-nowrap overflow-x-auto'>
          <MobileTopFilterAll isStoreProfile={isStoreProfile} />
          <MobileTopFilterStore />
          <MobileTopFilterCategory />
          <MobileTopFilterBrand />
          <MobileTopFilterPrice />
          <MobileTopFilterOnsale />
          <MobileTopFilterDistance />
          <MobileTopFilterCondition />
          <MobileTopFilterReserve />
          <div className={`filter-selected-clear-all`} onClick={onClearAll}>
            Clear All
          </div>
        </div>
      </div>
    </>
  )
}
