import React, { useEffect, useState } from 'react'
// import { Button, message, Steps, theme } from 'antd'
import { Steps } from 'antd'
import RegisterStep0 from './components/RegisterStep0'
import RegisterStep1 from './components/RegisterStep1'
import RegisterStep2 from './components/RegisterStep2'
import RegisterStep3 from './components/RegisterStep3'
// import RegisterStep4 from './components/RegisterStep4'
import RegisterStep5 from './components/RegisterStep5'
import { HelmetProvider, Helmet } from "react-helmet-async"
import { Config } from '@Config'
import { useSelector } from 'react-redux'

export default function RegisterSteps() {

    const isMobile = useSelector(state => state.content.isMobile)
    // const userinfo = useSelector(state => state.user.userinfo)
    const [current, setCurrent] = useState(0)
    const recaptchaRef = React.useRef(null)
    const [recaptchaCode, setRecaptchaCode] = React.useState('')

    // useEffect(() => {
    //     if (userinfo && current === 0) {
    //         setCurrent(1)
    //     }
    // }, [userinfo, current])

    const steps = [
        {
            title: 'Select Plan',
            content: <RegisterStep0
                setCurrent={setCurrent}
                recaptchaRef={recaptchaRef}
                recaptchaCode={recaptchaCode}
                setRecaptchaCode={setRecaptchaCode}
                isMobile={isMobile}
            />,
        },
        {
            title: 'Create Account',
            content: <RegisterStep1
                setCurrent={setCurrent}
                recaptchaRef={recaptchaRef}
                recaptchaCode={recaptchaCode}
                setRecaptchaCode={setRecaptchaCode}
                isMobile={isMobile}
            />,
        },
        {
            title: 'Create Store',
            content: <RegisterStep2 setCurrent={setCurrent} isMobile={isMobile} />,
        },
        {
            title: 'Payment',
            content: <RegisterStep3 setCurrent={setCurrent} isMobile={isMobile} />,
        },
        // {
        //     title: 'Book Setup',
        //     content: <RegisterStep4 setCurrent={setCurrent} />,
        // },
        {
            title: 'Finish',
            content: <RegisterStep5 isMobile={isMobile} />,
        },
    ]


    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }))
    const contentStyle = {
        textAlign: 'center',
        color: '#000',
        backgroundColor: '#fff',
        borderRadius: 8,
        border: `1px dashed #d9d9d9`,
        marginTop: 16,
    }
    return (
        <div className='w-[1280px] m-auto p-10 smsize:w-full smsize:p-3'>
            <HelmetProvider>
                <Helmet>
                    <title>Register - {Config.appName}</title>
                </Helmet>
            </HelmetProvider>
            <Steps current={current} items={items} />
            <div style={contentStyle}>{steps[current]?.content}</div>
            {/* <div
                style={{
                    marginTop: 24,
                }}
            >
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => message.success('Processing complete!')}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Previous
                    </Button>
                )}
            </div> */}
        </div>
    )
}