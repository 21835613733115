import React from 'react'
import GiftCards from './components/GiftCards'

export default function StoreGiftCard({
    isMobile,
    business,
    location
}) {
    return (
        <div className='w-full py-10'>
            <div className='m-auto w-full max-w-[1280px] flex justify-start gap-10 smsize:max-w-full smsize:flex-col smsize:p-5 smsize:gap-5'>
                <GiftCards business={business} isMobile={isMobile} />
            </div>
        </div>
    )
}
