
import React from 'react'
import { useSelector } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import './Products.css'
// import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getStoreCategories } from '@services/api.business.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
// import ItemCard from '../../zearch/components/ItemCard'
import SectionTitle from './SectionTitle'
import BrandListCard from '../../zearch/components/BrandListCard'

export default function StoreBrands({
    business,
    width,
    isMobile,
    setFilterBrandCheckOne
}) {
    const location = useSelector(state => state.user.location)

    const storeBrandRef = React.useRef(null)

    const onClickScrollBtn = (step) => {
        if (!storeBrandRef.current)
            return
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            storeBrandRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1000) {
                clearInterval(slideTimer)
            }
        }, 20)
    }

    const onClickBrand = (id) => {
        console.log('id', id)
        setFilterBrandCheckOne({
            _id: id,
            checked: true
        })
    }

    if (business?.brands?.length === 0)
        return null

    console.log('StoreBrands width', width)

    return (
        <section
            style={{
                maxWidth: isMobile ? '100%' : `${width - 130}px`
            }}
            className="w-full flex flex-col relative "
        >
            <SectionTitle title={'Brands'} />
            {/* <div className='relative'> */}
            <div className="w-full py-1 overflow-x-hidden overflow-y-hidden smsize:overflow-x-auto flex gap-5 text-left scroll-wrap smsize:gap-3" ref={storeBrandRef}>
                <span className='scroll-left' style={{ top: '100px' }} onClick={() => onClickScrollBtn(-50)}><AiOutlineLeft /></span>
                <span className='scroll-right' style={{ top: '100px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                {business?.brands?.map((brand, index) => (
                    <BrandListCard
                        key={index}
                        item={brand}
                        onClickBrand={onClickBrand}
                        toLink=''
                    />
                ))}
            </div>
        </section>
    )
}
