/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Retail is a functional React component that serves as the main page for the retail section of the website.
 * It includes the RetailHero, Tier, and Howitworks components, and a form to get more information.
 * It also includes a modal for scheduling a call, which can be opened from the RetailHero, Tier, and Howitworks components.
 *
 * @author James Nguyen, Yang Ming
 * @version January 26th, 2024
 */

import React, { useState } from 'react'
import './Retail.css'
import RetailHero from './components/RetailHero'
import { Config } from '@Config'
import { useSelector } from 'react-redux'
import { PopupModal } from 'react-calendly'
import Howitworks from './components/Howitworks'
import GetMoreInfoForm from './components/GetMoreInfoForm'
import Benefits from './components/Benefits'
import PlanListNew from './components/PlanListNew'
import PlanListMobile from './components/PlanListMobile'

//Main page for the retail section on fitzba.com
export default function Business() {

  const isMobile = useSelector(state => state.content.isMobile)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)


  return (
    <div>
      <GetMoreInfoForm isMobile={isMobile} isOpen={isOpenForm} setIsOpen={setIsOpenForm} />
      <RetailHero
        // handleClick={handleClick}
        setIsOpen={setIsOpen}
        isMobile={isMobile}
        setIsOpenForm={setIsOpenForm}
      />
      <div className='m-auto mt-8 smsize:mt-8 max-w-[1200px] px-5'>
        <h3 className='p-5 font-light text-[#fff] text-[22px] bg-[#202020] rounded-full smsize:w-full smsize:text-[16px]'>
          Fitzba is the only digital platform dedicated exclusively to local small businesses.
        </h3>
      </div>
      <Benefits />
      {/* {isMobile ?
        <PlanListNew
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          setIsOpenForm={setIsOpenForm}
        />
        : */}
        <PlanListNew
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          setIsOpenForm={setIsOpenForm}
        />
      {/* } */}
      <Howitworks
        setIsOpen={setIsOpen}
        isMobile={isMobile}
        setIsOpenForm={setIsOpenForm}
      />
      <PopupModal
        url={Config.calendlyUrl}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        styles={{ width: '10000px', height: '100%' }}
        rootElement={document.getElementById("root")}
      />
    </div>
  )
}
