import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, message, Alert } from 'antd'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { consumerToBusiness, getBusinessTypeList } from '@services/api.service'
import { setBusinessId, logout } from 'reducers/userReducer'
import { useSelector, useDispatch } from 'react-redux'
import { getUserBusinessList } from '@services/api.business.service'
import { useSearchParams } from 'react-router-dom'
export default function RegisterStep2({
    setCurrent,
    isMobile
}) {
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    const [formRef] = Form.useForm()
    const userinfo = useSelector(state => state.user.userinfo)
    const [provinceList, setProvinceList] = useState(
        canadaProvinces.map(item => ({ label: item.name, value: item.code })),
    )
    const [country, setCountry] = useState('CA')
    const [businessTypeList, setBusinessTypeList] = useState([])
    const [businessList, setBusinessList] = useState([{ name: 'New Store', _id: 'newStore' }])
    const [selectedStore, setSelectedStore] = useState('newStore')

    const onChangeSelectedStore = value => {
        setSelectedStore(value)
        formRef.resetFields()
        formRef.setFieldsValue({ selectedStore: value })
        if (value !== 'newStore') {
            const business = businessList.find(item => item._id === value)
            formRef.setFieldsValue(business)
        }
    }

    const onFinish = async (values) => {
        try {
            console.log('onFinish', selectedStore)
            // if (selectedStore === 'newStore') {
            const res = await consumerToBusiness({
                ...values,
                user_id: userinfo._id,
                memberType: searchParams.get('plan') || localStorage.getItem('plan'),
            })
            console.log('onFinish', res)
            message.success('Operation successful')
            dispatch(setBusinessId(res.business_id))
            // } else {
            // dispatch(setBusinessId(selectedStore))
            // message.success('Store has been selected successfully')
            // }
            setCurrent(3)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Register failed, please try again')
        }
    }

    useEffect(() => {

        const fetchBusinessTypeList = async () => {
            const res = await getBusinessTypeList()
            setBusinessTypeList(res.data)
            if (res.data.length > 0) {
                formRef?.setFieldsValue({ businessType: res.data[0]._id })
            }
            const list = await getUserBusinessList(userinfo._id)
            console.log('list', list)
            const tmpList = [{ name: 'Create New Store', _id: 'newStore' }, ...list.data]
            setBusinessList(tmpList)
            // setSelectedStore(tmpList[0]._id)
            // formRef.setFieldsValue({
            //     selectedStore: tmpList[0]._id
            // })
            const selectedId = tmpList[0]._id
            setSelectedStore(selectedId)
            formRef.resetFields()
            formRef.setFieldsValue({ selectedStore: selectedId })
            if (selectedId !== 'newStore') {
                const business = tmpList.find(item => item._id === selectedId)
                formRef.setFieldsValue(business)
            }
        }
        if (formRef) {
            formRef.setFieldsValue({
                selectedStore: 'newStore',
                country: 'CA',
                province: canadaProvinces[0].code,
            })
            fetchBusinessTypeList()
        }
    }, [formRef, userinfo?._id])

    const onChangeCountry = value => {
        setCountry(value)
        const dataList = value === 'CA' ? canadaProvinces : usStates
        setProvinceList(
            dataList.map(item => ({ label: item.name, value: item.code })),
        )
        formRef?.setFieldsValue({ province: dataList[0].code })
    }

    return (
        <div className='py-8 m-auto w-[500px] smsize:w-full'>
            <Alert className='mb-5' message="Note: You can create a new store or select
an existing store if your store is already existing in our system" type="info" />
            <Form
                form={formRef}
                name="createNewStore"
                // style={{
                //     maxWidth: 500,
                //     textAlign: 'left',
                // }}
                className='text-left max-w-[500px] smsize:px-3 smsize:max-w-full'
                onFinish={onFinish}
                layout='vertical'
                autoComplete="off"
            >
                <Form.Item name="selectedStore" label="Select a store" rules={[
                    {
                        required: true,
                        message: 'Store is required',
                    },
                ]}>
                    <Select options={businessList?.map(item => {
                        return { label: item.name, value: item._id }
                    })}
                        onChange={onChangeSelectedStore}
                    />
                </Form.Item>
                {/* {selectedStore === 'newStore' && ( */}
                <>
                    {/* <Form.Item name="businessType" label="Business Type" rules={[
                        {
                            required: true,
                            message: 'Business type is required',
                        },
                    ]}>
                        <Select
                            placeholder="Select a business type"
                            options={
                                businessTypeList?.map(item => {
                                    return { label: item.title, value: item._id }
                                })
                            }
                        />
                    </Form.Item> */}
                    <Form.Item
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <Form.Item name="name"
                            label="Store Name"
                            width="md"
                            rules={[
                                {
                                    required: true,
                                    message: 'Store name is required',
                                }
                            ]}
                            style={{
                                display: 'inline-block',
                                // width: 'calc(50% - 8px)',
                                width: isMobile ? '100%' : 'calc(50% - 8px)',
                                marginRight: '16px',
                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="businessPhone"
                            label="Business Phone Number"
                            width="sm"
                            rules={[
                                {
                                    required: true,
                                    message: 'Phone number is required',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                // width: 'calc(50% - 8px)',
                                width: isMobile ? '100%' : 'calc(50% - 8px)',
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="storeName"
                        label="Business Name (if different than store name)"
                        width="md">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: 0,
                        }}
                    >

                        <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Country is required',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                                marginRight: '16px',
                            }}
                            width="xs"

                        >
                            <Select options={[
                                {
                                    label: 'Canada',
                                    value: 'CA',
                                },
                                {
                                    label: 'United States',
                                    value: 'US',
                                },

                            ]}
                                onChange={onChangeCountry}
                            />
                        </Form.Item>
                        <Form.Item
                            name="province"
                            label={country === 'CA' ? 'Province' : 'State'}
                            rules={[
                                {
                                    required: true,
                                    message: 'State is required',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',

                            }}
                        >
                            <Select options={provinceList} />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="address" label="Address" width="md" rules={[
                        {
                            required: true,
                            message: 'Address is required',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <Form.Item name="city" label="City" width="md" rules={[
                            {
                                required: true,
                                message: 'City is required',
                            },
                        ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                                marginRight: '16px',
                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="postalCode"
                            label={country === 'CA' ? 'PostalCode' : 'Zip'}
                            rules={[
                                {
                                    required: true,
                                    message: 'PostalCode is required',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                            }}
                            width="xs"
                        >
                            <Input />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item style={{
                        marginBottom: 0,
                    }}>
                        {/* <Form.Item name="reservationEmail"
                            label="Reservation Email"
                            width="sm"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Reservation email is not valid',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                                marginRight: '16px',
                            }}
                        >
                            <Input />
                        </Form.Item> */}
                        <Form.Item name="website"
                            label="Website"
                            width="sm"
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Form.Item>
                </>
                {/* )} */}
                <Form.Item style={{
                    marginBottom: 0,
                }}>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                        marginRight: '16px',
                    }}>
                        <Button
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                            onClick={() => {
                                dispatch(logout())
                                setCurrent(1)
                            }}
                        >
                            Previous
                        </Button>
                    </Form.Item>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                    }}>
                        <Button
                            htmlType='submit'
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-black py-2 px-5 text-white hover:bg-slate-200'
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>
        </div>
    )
}
