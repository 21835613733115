/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * ShoppingCart is a functional React component that displays the user's shopping cart.
 * It shows a list of items in the cart, the subtotal, and a button to continue to the summary.
 * If the cart is empty, it shows a message indicating that the cart is empty.
 * Additionally it provides a button to continue shopping, which redirects the user to the search page.
 * The user can also update or delete items in the cart.
 *
 * @author James Nguyen, Yang Ming
 * @version October 13th, 2023
 */

import React, { useState } from 'react'
import './ShoppingCart.css'
import CartEmpty from './components/CartEmpty'
import CardSummary from './components/CartSummary'
import CardItemList from './components/CartItemList'
import { useSelector, useDispatch } from 'react-redux'
import { formatCurrency } from '@utils/Helper'
import { setGotoElement } from '@reducers/contentReducer'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { Link } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'
import CartReservation from './components/CartReservation'
import { Modal, Spin, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { shoppingCartCheckout } from '@services/api.shoppingCart.service'
import CartShippingForm from './components/CartShippingForm'

export default function ShoppingCart() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const shoppingCart = useSelector(state => state.user.shoppingCart)
  const location = useSelector(state => state.user.location)
  const isMobile = useSelector(state => state.content.isMobile)
  const userinfo = useSelector(state => state.user.userinfo)
  const [showPopup, setShowPopup] = useState(false)
  const [selectedStore, setSelectedStore] = useState('') // business_id
  const [isCheckout, setIsCheckout] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const { updateCart, deleteCart } = ShoppingCartHook()
  const [promoCode, setPromoCode] = useState('')

  // const cartData = shoppingCart?.data?.data?.filter(
  //   item => item.business._id === selectedStore,
  // )[0]

  console.log('shoppingCart', shoppingCart)

  const business = selectedStore
    ? shoppingCart?.data?.data?.find(item => item._id === selectedStore)
        ?.business
    : null

  const handleSubmit = async () => {
    const hide = message.loading('Checkout in progress...')
    setIsSubmitting(true)
    try {
      const res = await shoppingCartCheckout({
        business_id: selectedStore,
      })
      hide()
      setIsCheckout(true)
      setTimeout(() => {
        // redirect to "google.com" add blank
        setIsSubmitting(false)
        window.open(res?.data?.pos_checkout_webUrl, '_blank')
      }, 2000)
    } catch (error) {
      console.log('checkout error', error)
      hide()
      setIsSubmitting(false)
      message.error(
        'Checkout failed: ' + error?.data?.message || 'Please try again',
      )
    }
  }

  if (
    shoppingCart.data === null ||
    shoppingCart.data?.status === 'error' ||
    shoppingCart.data?.totalItems === 0
  )
    return (
      <div className='cart-wrapper'>
        <CartEmpty />
      </div>
    )

  return (
    <div className='cart-wrapper'>
      {showPopup && selectedStore && (
        <CartReservation
          userinfo={userinfo}
          isMobile={isMobile}
          // cartData={cartData}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          selectedStore={selectedStore}
          shoppingCart={shoppingCart}
        />
      )}
      <Modal
        title={isSubmitting ? 'Checkout in progress...' : 'Checkout'}
        centered
        open={isCheckout}
        onOk={() => setIsCheckout(false)}
        onCancel={() => setIsCheckout(false)}
        // hide footer
        footer={null}
      >
        {isOpenConfirm ? (
          <div className='flex flex-col items-start justify-center gap-3'>
            {isSubmitting ? (
              <>
                <Spin size='large' tip='Loading' />
                <p>
                  After submitting the checkout, you’ll be automatically
                  redirected to the payment page upon completion.
                </p>
              </>
            ) : (
              <p>
                Checkout submitted successfully, please proceed to complete the
                payment.
              </p>
            )}
            <div className='mt-3 flex justify-end gap-5'>
              <button
                type='button'
                onClick={() => navigate('/my-fitzba')}
                className='flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-white px-10 py-[6px] uppercase text-fitzba-bright-gold-static hover:bg-fitzba-bright-gold-static hover:text-white'
              >
                CLOSE
              </button>
              <button
                type='button'
                onClick={() => navigate('/my-fitzba')}
                className='flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] uppercase text-white hover:bg-white hover:text-fitzba-bright-gold-static'
              >
                FINISH
              </button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-start justify-center gap-3'>
            <p>
              You will be redirected to <strong>{business?.name}</strong> to
              complete payment.
            </p>
            <div className='mt-3 flex justify-end gap-5'>
              <button
                type='button'
                onClick={() => {
                  setIsOpenConfirm(false)
                  setIsCheckout(false)
                }}
                className='flex w-[100px] cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-white px-10 py-[6px] uppercase text-fitzba-bright-gold-static hover:bg-fitzba-bright-gold-static hover:text-white'
              >
                CANCEL
              </button>
              <button
                type='button'
                onClick={() => {
                  setIsOpenConfirm(true)
                  handleSubmit()
                }}
                className='flex w-[100px] cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] uppercase text-white hover:bg-white hover:text-fitzba-bright-gold-static'
              >
                OK
              </button>
            </div>
          </div>
        )}
      </Modal>
      <div className='cart-main'>
        <div className='cart-content'>
          {/* {!isCheckout && ( */}
          <>
            <div className='cart-content-header'>
              <Link to='/search' className='cart-button-continue'>
                <AiOutlineLeft /> Continue shopping
              </Link>
              <h3>Shopping Cart</h3>
              {/* <p>
                  The items in your <strong>LIST</strong> are available for
                  in-store pay and pick-up only. <br />
                  Here's a <strong>SHOPPING LIST</strong> to help you plan your
                  shopping trip.
                </p> */}
            </div>
            <div className='cart-content-continue'>
              <div>
                <h4>Subtotal</h4>
                <p>{formatCurrency(shoppingCart?.data?.subTotal)}</p>
              </div>
              <button
                onClick={() => {
                  dispatch(setGotoElement('cart-summary'))
                }}
              >
                Continue
              </button>
            </div>
            <CardItemList
              isMobile={isMobile}
              setSelectedStore={setSelectedStore}
              shoppingCart={shoppingCart}
              location={location}
              setShowPopup={setShowPopup}
              handleUpdateCart={updateCart}
              handleDeleteCart={deleteCart}
              isCheckout={isCheckout}
              setIsCheckout={setIsCheckout}
              userinfo={userinfo}
            />
          </>
          {/* )} */}
          {/* {isCheckout && (
            <CartShippingForm
              isCheckout={isCheckout}
              setIsCheckout={setIsCheckout}
              userinfo={userinfo}
              selectedStore={selectedStore}
              shoppingCart={shoppingCart} 
            />
          )} */}
        </div>
        <CardSummary
          shoppingCart={shoppingCart}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          selectedStore={selectedStore}
          isCheckout={isCheckout}
        />
      </div>
    </div>
  )
}
