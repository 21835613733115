import React from 'react'
import { FaCanadianMapleLeaf } from "react-icons/fa"

export default function CanadianBrandIcon({
    size = 25,
    bottom = 10,
    right = 10,
    top = 0,
}) {

    return (
        <FaCanadianMapleLeaf
            style={{ 
                bottom : bottom ? bottom : 'auto', 
                right,
                top: top ? top : 'auto', 
                width: size, 
                height: size 
            }}
            className='z-[10] absolute rounded-full bg-white border-[#ff0000] text-[#ff0000] flex items-center justify-center' />
    )
}
