import React from 'react'
import BenefitsCard from './BenefitsCard'

const benefitsList = [
    {
        title: 'Increase visibility',
        description: 'Showcase your store, brands, and products enhancing your online presence and attracting new customers.',
        image: 'images/retail/increase-visibility.png'
    },
    {
        title: 'Drive in-store sales',
        description: 'Enable customers to browse and reserve items from the comfort of their homes, boosting foot traffic and sales.',
        image: 'images/retail/drive-in-store-sales.png'
    },
    {
        title: 'Be Part of the Future',
        description: 'As a launch partner, you will have early access to new features and opportunities to  shape the future of bricks-and- mortar retail.',
        image: 'images/retail/be-part-of-the-future.png'
    },
]

export default function Benefits() {


    return (
        <div className='py-5 smsize:py-5 m-auto max-w-[1200px] flex flex-col items-center justify-center gap-10'>
            <h3 className='text-[48px] font-medium uppercase smsize:text-[30px]'>Discover the Benefitz</h3>
            <div className='flex items-center justify-center gap-[20px] smsize:flex-col smsize:gap-[30px]'>
                {benefitsList.map((benefit, index) => (
                    <BenefitsCard
                        key={index}
                        title={benefit.title}
                        description={benefit.description}
                        image={benefit.image}
                    />
                ))}
            </div>
        </div>
    )
}
