import React, { useEffect, useState } from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setSearchFilter } from '@reducers/contentReducer'
// import { debounce } from 'lodash'
import { CiShoppingTag } from "react-icons/ci"
import { CiViewList } from "react-icons/ci"
import FilterSideCategory from '../../store/components/FilterSideCategory'

export default function MobileTopFilterCategoryStoreProfile({
    business_id
}) {
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    // const filterList = useSelector(state => state.content.filterList)
    const isMobile = useSelector(state => state.content.isMobile)

    return (
        <>
            <MobileTopFilterItem
                selectedable={true}
                firstIcon={<CiViewList size={16} />}
                text={itemsFilter?.categories?.length > 0 ? `Category (${itemsFilter?.categories?.length})` : 'Category'}
                onClick={() => {
                    setOpen(true)
                }}
                isSelected={itemsFilter?.categories?.length > 0}
            />
            <Drawer
                title={''}
                closeIcon={''}
                className='filter-mobile-drawer'
                placement={'bottom'}
                onClose={() => setOpen(false)}
                open={open}
                key={'mobile-filter-price'}
                height={'80%'}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                extra={
                    <div className='flex justify-end'>
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                }
                // extra={<NavZearchBar />}
                style={{
                    // height: '100vh',
                    textAlign: 'left',
                    overflowY: 'auto',
                    paddingBottom: 20,
                    // backgroundColor: '#202020',
                    borderRadius: '10px 10px 0 0',
                }}
                footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center text-white'
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    View results
                </button>}
            >
                <div className='mt-5 flex flex-col gap-3 justify-between h-full'>
                    <FilterSideCategory
                        itemsFilter={itemsFilter}
                        dispatch={dispatch}
                        isMobile={isMobile}
                        business_id={business_id}
                        color={'#202020'}
                        bgColor='#fff'
                        isDrawer={true}
                    />
                </div>
            </Drawer>
        </>
    )
}
