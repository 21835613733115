/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RetailButtons is a functional React component that displays two buttons: "Book a call" and "Get more info".
 *
 * @author Yang Ming, James Nguyen, Joshua Soke
 * @version January 26th, 2024
 */

import React from 'react'
import { Link } from 'react-router-dom'

export default function RegisterButtons() {
    const btns = [
        <Link
            key={'for-business'}
            to={'/for-business'}
            className='flex px-10 py-3 smsize:w-full cursor-pointer justify-center items-center rounded-full border  border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-[18px] text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static uppercase'
        >
            retailers: create a free profile
        </Link>,
        <a
            target={'_blank'}
            rel={'noreferrer'}
            href={'https://docs.google.com/forms/d/e/1FAIpQLSfenVCUJzapWZW2hp1kG5sk3_-QZ9RpVaOBIky5EeEmk3068g/viewform'}
            key={'consumers-nominate'}
            className='whitespace-nowrap flex px-10 py-3 smsize:w-full cursor-pointer justify-center items-center rounded-full border border-black text-white bg-black text-[18px] text-base hover:bg-white hover:border-fitzba-bright-gold-static hover:text-fitzba-bright-gold-static uppercase'
        >
            consumers: nominate a store
        </a>
    ]
    return (
        <div className={`whitespace-nowrap pb-5 w-full flex justify-center items-center smsize:flex-col gap-[25px] smsize:gap-[15px] smsize:p-5`}>
            {btns}
        </div>
    )
}
