import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Space, Radio, Alert, Checkbox } from 'antd'
import { isCreditCardValid, isExpiryDateValid, isCVCValid } from '@utils/Helper'
import { paymentCreditCard, checkRegisterPromoCode } from '@services/api.user.service'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const BASE_PRICE = 99
export default function RegisterStep3({
    setCurrent,
    isMobile
}) {
    const [searchParams] = useSearchParams()
    const [note, setNote] = useState('* Your first payment starts DATE = TODAY + 1 MONTH.')
    const userinfo = useSelector(state => state.user.userinfo)
    const business_id = useSelector(state => state.user.business_id)
    const [price, setPrice] = useState(BASE_PRICE)
    const [formRef] = Form.useForm()
    const [promoCode, setPromoCode] = useState('')
    const [promoValue, setPromoValue] = useState(0)
    const [planType, setPlanType] = useState('monthly')
    const [memberType, setMemberType] = useState('')
    const [oneTimeSetup, setOneTimeSetup] = useState(false)

    useEffect(() => {
        const plan = localStorage.getItem('plan') || localStorage.getItem('plan')
        if (plan) {
            setMemberType(plan)
            if (formRef) {
                formRef.setFieldsValue({
                    memberType: plan
                })
            }
        }
    }, [searchParams, formRef])

    const checkCardNumber = async (_, cardNumber) => {
        // return Promise.resolve()

        if (!cardNumber) {
            return Promise.resolve()
        }
        // return true
        if (!isCreditCardValid(cardNumber)) {
            throw new Error('Card number is invalid')
        }
    }

    const checkExpireDate = async (_, expireDate) => {
        if (!expireDate) {
            return Promise.resolve()
        }
        if (expireDate.length < 4 || expireDate.length > 5) {
            throw new Error('Expire date is invalid')
        }
        if (!isExpiryDateValid(expireDate)) {
            throw new Error('Expire date is invalid')
        }
    }

    const checkCvv = async (_, cvv) => {
        if (!cvv) {
            return Promise.resolve()
        }
        if (!isCVCValid(cvv)) {
            throw new Error('CVV code is invalid')
        }
    }

    const onFinish = async (values) => {
        try {
            if (memberType === 'startz' && !oneTimeSetup) {
                // message.success('Select plan successfully!')
                setCurrent(4)
                return
            }
            // if (values.planType !== 'free' && values?.planAmount <= 0) {
            //     message.error('The amount must be greater than 0')
            //     return
            // }
            console.log('business_id', business_id)
            const res = await paymentCreditCard({
                ...values,
                planType: planType,
                planAmount: price,
                user_id: userinfo._id,
                business_id: business_id || userinfo?.currentBusiness?._id,
                isCustom: false,
                promoCode,
                memberType
            })
            console.log('onFinish', res)
            message.success('Setup paymen method successfully!')
            setCurrent(4)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Somthing wrong, please try again')
        }
    }

    const checkPromoCode = async (values) => {
        if (!promoCode) {
            message.error('Please enter a promo code')
            return
        }
        try {
            const res = await checkRegisterPromoCode({
                promoCode
            })
            console.log('checkPromoCode', res)
            if (res?.status !== 'success') {
                setPrice(BASE_PRICE)
                message.error(res?.message || 'Promo code is invalid')
                return
            }

            message.success('Promo code applied successfully')
            if (promoCode === 'FOUNDER50') {
                setNote('* Your first month is free, then $50/month per storefront.')
            } else if (promoCode === 'FOUNDER3FREE') {
                setNote('* Your first 3 months are free, then $99/month per storefront.')
            }
            const value = res?.value || 0
            setPromoValue(res?.value)
            // setPrice(BASE_PRICE - value)
            if (planType === 'yearly') {
                setPrice((BASE_PRICE - value) * 10 + 9)
            } else {
                setPrice(BASE_PRICE - value)
            }
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Action failed, please try again')
        }
    }

    const changePlanType = (value) => {
        setPlanType(value)
        if (value === 'yearly') {
            setPrice((BASE_PRICE - promoValue) * 10 + 9)
        } else {
            setPrice(BASE_PRICE - promoValue)
        }
    }

    const onClickOneTimeSetup = (value) => {
        setOneTimeSetup(value)
        setPrice(value ? 47 : 0)
    }

    return (
        <div className='py-8 m-auto w-[500px] smsize:w-full'>
            {/* <Tier /> */}
            <Form
                form={formRef}
                name="createNewStore"
                // style={{
                //     maxWidth: 500,
                //     textAlign: 'left',
                // }}
                className='text-left max-w-[500px] smsize:px-3 smsize:max-w-full'
                onFinish={onFinish}
                layout='vertical'
                autoComplete="off"
            >

                {/* <Form.Item
                    name='memberType'
                    value={memberType}
                    onChange={e => {
                        setMemberType(e?.target?.value)
                        setPrice(e?.target?.value === 'startz' ? 0 : BASE_PRICE)
                    }}
                >
                    <Radio.Group size='large'>
                        <Radio.Button value='startz'>STARTZ - Free</Radio.Button>
                        <Radio.Button value='sellz'>SELLZ - $99/mon</Radio.Button>
                    </Radio.Group>
                </Form.Item> */}
                {memberType && (
                    <>
                        {memberType === 'startz' && (
                            <div className='mb-2'>
                                <h1 className='mb-2 text-[25px] font-bold'>STARTZ</h1>
                                <h3 className='mb-2 text-[22px] font-[300]'>Free</h3>
                                <Alert type='info' message={`Need Help? Skip the setup and let us do it for you. We'll create your store profile for you, so you can focus on running your business!`} />
                                <br/>
                                <Form.Item
                                    name='oneTimeSetup'
                                    label='One-time setup (optional)'
                                >
                                    <Checkbox
                                        size='large'
                                        checked={oneTimeSetup}
                                        onChange={(e) => {
                                            onClickOneTimeSetup(e.target.checked)
                                        }}

                                    >
                                        I'd like to add one-time setup service
                                    </Checkbox>
                                    <span className='text-[20px]'>$47</span>
                                </Form.Item>

                            </div>
                        )}

                        {memberType === 'sellz' && (
                            <>
                                <div className='mb-2'>
                                    <h1 className='mb-2 text-[25px] font-bold'>SELLZ</h1>
                                    <h3 className='mb-2 text-[22px] font-[300]'>STOREFRONT PARTNERZ</h3>
                                    <p><span className='text-[30px]'>${price}/month</span>{' '}(per storefront)</p>
                                </div>
                                <Form.Item
                                    name='planType'
                                    value={planType}
                                    onChange={e => {
                                        changePlanType(e?.target?.value)
                                    }}
                                >
                                    <Radio.Group size='large' defaultValue="monthly">
                                        <Radio.Button value='monthly'>Monthly</Radio.Button>
                                        <Radio.Button value='yearly'>Yearly</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <div className='smsize:flex smsize:flex-col'>
                                    <h3 className='mb-2 text-[22px] font-[300]'>Discount</h3>
                                    <Form.Item
                                        name='promoCode'
                                        label='Promo code (optional)'
                                        className='flex smsize:flex-col'
                                    >
                                        <Space direction="horizontal">
                                            <Input
                                                size='large'
                                                placeholder='Promo code'
                                                allowClear
                                                onChange={(e) => {
                                                    setPromoCode(e.target.value)
                                                }}
                                            />
                                            <Button
                                                size='large'
                                                onClick={checkPromoCode}
                                            >
                                                Apply
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </>
                        )}
                        {(oneTimeSetup || memberType === 'sellz') && (
                            <div>
                                <h3 className='mb-2 text-[22px] font-[300]'>Payment method</h3>
                                <Form.Item
                                    name='cardName'
                                    label='Name on card'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input card name',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                        placeholder='Name on card'
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='cardNumber'
                                    label='Card number'
                                    rules={[
                                        {
                                            validator: checkCardNumber,
                                        },
                                        {
                                            required: true,
                                            message: 'Please input card number',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                        placeholder='Card number'
                                        suffix={<img src='/images/credit-icon.png' alt='' />}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginBottom: 0,
                                    }}
                                >
                                    <Form.Item
                                        name='cardExpireDate'
                                        rules={[
                                            {
                                                validator: checkExpireDate,
                                            },
                                            {
                                                required: true,
                                                message: 'Please input expiry date',
                                            },
                                        ]}
                                        style={{
                                            display: 'inline-block',
                                            width: 'calc(50% - 8px)',
                                            marginRight: '16px',
                                        }}
                                    >
                                        <Input
                                            size='large'
                                            placeholder='MM/YY'
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='cardCvv'
                                        rules={[
                                            {
                                                validator: checkCvv,
                                            },
                                            {
                                                required: true,
                                                message: 'Please input cvv',
                                            },
                                        ]}
                                        style={{
                                            display: 'inline-block',
                                            width: 'calc(50% - 8px)',
                                        }}
                                    >
                                        <Input
                                            size='large'
                                            placeholder='CVV'
                                            allowClear
                                        />
                                    </Form.Item>
                                </Form.Item>
                                <div className="text-red-500">{note}</div>
                                <div className='mt-3 flex items-center justify-center gap-1'>
                                    <p>Powered by</p>
                                    <img
                                        width={40}
                                        src='/images/payment/stripe-logo.webp'
                                        alt='stripe'
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                <Form.Item style={{
                    marginBottom: 0,
                }}>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                        marginRight: '16px',
                    }}>
                        <Button
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                            onClick={() => setCurrent(2)}
                        >
                            Previous
                        </Button>
                    </Form.Item>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                        marginRight: '16px',
                    }}>
                        <Button
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                            onClick={() => setCurrent(4)}
                        >
                            Skip
                        </Button>
                    </Form.Item>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                    }}>
                        <Button
                            htmlType='submit'
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-black py-2 px-5 text-white hover:bg-slate-200'
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>
        </div>
    )
}
