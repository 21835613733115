import React from 'react'
import YoutubeEmbed from './components/YoutubeEmbed'
import YouTubePlaylist from './components/YoutubeDemoList'
import { useSelector } from 'react-redux'

export default function YoutubeDemo() {

    const setting = useSelector(state => state.user.setting)

    return (
        <div className='my-6 flex flex-col bg-white smsize:p-3'>
            <div className='m-auto w-full max-w-[1024px] flex flex-col gap-5 smsize:w-full'>
                <h1 className='uppercase text-center text-[30px] smsize:text-[20px]'>YouTube Video</h1>
                <div className='w-full flex items-center justify-center'>
                    <YoutubeEmbed videoId={setting?.youtube_video_id || '8N3uygRII-4'} />
                </div>
                <br />
                <br />
                <h1 className='uppercase text-center text-[30px] smsize:text-[20px]'>YouTube Playlist</h1>
                <YouTubePlaylist playlistId={setting?.youtube_playlist_id || 'PLC77007E23FF423C6'} />
            </div>
        </div>
    )
}
