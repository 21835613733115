import React from 'react'

export default function HeaderEvent0331() {
    return (
        <div className='flex py-3 w-full items-center justify-between bg-[#222222] px-5 text-white'>
            <a href='https://www.fitzba.com' rel="noreferrer">
                <img src='/logo-white.png' className='w-[120px] cursor-pointer smsize:w-[100px]' alt='Fitzba.com' />
            </a>
            <div className='flex gap-3 items-center justify-center'>
                <div className='smsize:text-[12px] uppercase'>Consumer Retailer Conversation 20250331</div>
            </div>
        </div>
    )
}
