import React from 'react'
import { addFavoriteStores } from '@services/api.user.service'
import { setIsRefreshUserinfo, setUserinfo } from '@reducers/userReducer'
import { useDispatch } from 'react-redux'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { message, Modal } from 'antd'
const { confirm } = Modal

export default function StoreRemoveButton({
    store_id,
    userinfo,
    setIsRefresh
}) {

    const dispatch = useDispatch()
    const onClick = async () => {
        try {
            const res = await addFavoriteStores({
                storeIds: [store_id],
                action: 'remove'
            })
            dispatch(setUserinfo({
                ...userinfo,
                favoriteStores: res.data
            }))
            setIsRefresh(true)
            message.success('Store removed from favorite list')
            // dispatch(setIsRefreshUserinfo(true))
        } catch (error) {
            console.log(error)
        }
    }

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete this favorite store?',
            icon: <ExclamationCircleFilled />,
            content: 'The favorite store will be deleted permanently',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                console.log('OK')
                onClick()
            },
            onCancel() {
                console.log('Cancel')
            },
        })
    }

    return (
        <div className='absolute top-0 right-0 z-[666] cursor-pointer rounded-full border w-[25px] h-[25px] bg-white'>
            <DeleteOutlined className='text-red-600' onClick={showDeleteConfirm} size={24} />
        </div>
    )
}
