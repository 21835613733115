import React from 'react'
import './GiftGuide.css'
import { contentCode } from '@data/contentCode.data'
import ContentDataHook from '@hooks/ContentData.hook'
import Loading from '@components/Loading'
import Header from './components/Header'
import PageNumbers from './components/PageNumbers'
import LeftAndRightBtns from './components/LeftAndRightBtns'
import DropDown from './components/DropDown'
import GiftGuideTemplate from './components/GiftGuideTemplate'

export default function GiftGuide2({
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  index,
  setIndex,
  selectItems,
}) {
  const {
    isPending,
    error,
    data: content,
  } = ContentDataHook(contentCode.GIFT_GUIDE_DESC)
  if (isPending) return <Loading />
  if (error) return 'GiftGuide: An error has occurred: ' + error.message

  return (
    <GiftGuideTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='w-full'>
        <div className='relative flex w-full items-center justify-between pt-[100px] pr-5 smsize:pt-[40px]'>
          <img
            className='absolute top-[300px] right-5 w-[200px] smsize:top-[150px] smsize:w-[120px] smsize:opacity-80'
            src='/gift-guide/snow-leaf.png'
            alt='Snow flake'
          />
          <img
            src='/gift-guide/message-from-chamber.png'
            alt={content?.title}
            className='object-cover smsize:w-[300px]'
          />
          <h1 className='absolute top-10 right-10 text-right text-[80px] uppercase leading-[80px] text-[#520a0a] smsize:top-5 smsize:text-[40px] smsize:leading-[40px]'>
            {/* A message from FITZBA */}
            A message
            <br />
            from
            <br />
            <span className='font-light'>FITZBA</span>
          </h1>
        </div>
        <div>
          <div className='flex h-[60px] w-[445px] items-center justify-start bg-[#520a0a] smsize:h-[40px] smsize:w-full'>
            <a
              className='w-full text-white underline'
              href='https://www.fitzba.com'
              // target='_blank'
              rel='noreferrer'
            >
              www.fitzba.com
            </a>
          </div>
          <div className='mt-3 flex justify-end pr-5 smsize:mt-0 smsize:p-3 smsize:pr-0'>
            <p
              className='font-ligh w-[70%] text-left text-[14px] leading-4 smsize:w-full'
              dangerouslySetInnerHTML={{ __html: content?.content }}
            />
          </div>
        </div>
      </div>
    </GiftGuideTemplate>
  )
}
