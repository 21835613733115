
import React from 'react'
import { useSelector } from 'react-redux'
// import { setStoreProfileFilter } from '@reducers/contentReducer'
import { Avatar, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { getStoreLink, getDistance } from '@utils/Helper'
import StoreRemoveButton from './StoreRemoveButton'
/**
 *
 * @param item              a json that contain the store info
 * @param userinfo          a json that contain the user info, we use this
 *                          to dertermined if a user is loged in or not
 * @returns Render an Store card for each item
 */
export default function StoreCard({ item, stores, keywordsList, setIsRefresh, userinfo }) {
  // const navigate = useNavigate()
  // const dispatch = useDispatch()
  const location = useSelector((state) => state.user.location)
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  // console.log('StoreCard', item)
  const handleClickStore = () => {
    console.log('handleClickStore', itemsFilter)
  }

  const link = getStoreLink(item?.shortLink, item?.city, item?.province)

  const borderStyle = stores?.includes(item._id) ? {
    borderWidth: '2px',
    borderColor: '#E4B456',
  } : {
    borderWidth: '1px',
    borderColor: '#d9d9d9',
  }
  return (
    <div className='flex flex-col w-fit text-center font-lexend md:w-44 items-center justify-center'>
      <div className=' relative border-1 h-28 w-28 rounded-full border-black bg-white md:h-40 md:w-40'>
        <StoreRemoveButton store_id={item?._id} userinfo={userinfo} setIsRefresh={setIsRefresh} />
        <Link onClick={handleClickStore} to={link}>
          {item?.logo ? (
            <img
              src={item?.logo}
              className={`aspect-[4/3] rounded-full border object-contain`}
              style={{ height: '100%', width: '100%', ...borderStyle }}
              alt={item?.name}
            />
          ) : (
            <Avatar
              className={`font-sbold mx-auto cursor-pointer break-words border bg-fitzba-white-static align-middle font-anke text-2xl text-fitzba-off-black-static`}
              size={{ xs: 112, sm: 112, md: 160, lg: 160, xl: 160, xxl: 160 }}
              style={borderStyle}
            >
              {item?.name}
            </Avatar>
          )}
        </Link>
      </div>
      <Tooltip title={item.name} placement='bottom'>
        <div className='store_name truncate-multiline smsize:text-[12px] break-all' style={{ textAlign: 'center' }}>
          <Link onClick={handleClickStore} to={link}>
            {item.name}
          </Link>
        </div>
      </Tooltip>
      <div className='text-[11px] lg:text-[15px] text-center'>
        {getDistance(item?.distance, location?.country === 'US')}
      </div>
    </div>
  )
}
