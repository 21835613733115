import React from 'react'
import YouTube from 'react-youtube'

const YoutubeEmbed = ({
    videoId,
    width,
    height,
    extraClass
}) => {
    // const videoId = '8N3uygRII-4' // YouTube ID

    // config for YouTube player
    const opts = {
        width: width ? width : '800',
        height: height ? height : '450',
        playerVars: {
            autoplay: 0, // autoplay
            modestbranding: 1, // hide YouTube Logo
            rel: 0, // don't show related videos
            fs: 0, // hide full screen button
            controls: 1, // show controls bar
        },
    }

    const onEnd = (event) => {
        event.target.stopVideo() // stop video
        event.target.clearVideo() // clear video
    }

    return (
        <YouTube className={extraClass} videoId={videoId} opts={opts} onEnd={onEnd} />
    )
}

export default YoutubeEmbed