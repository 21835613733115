import React from 'react'
import PlanIcon from './PlanIcon'
import RetailButtons from './RetailButtons'
import { Link } from 'react-router-dom'

export default function PlanListNew({
    isMobile,
    setIsOpen,
    setIsOpenForm
}) {

    return (
        <div className='bg-[#F9EDD6] flex flex-col gap-10 p-10 m-auto text-center items-center justify-center smsize:p-1'>
            <div >
                <table className='plan-table'>
                    <tbody>
                        <tr>
                            <th rowSpan="2" className='plan-big-title'>
                            </th>
                            <th valign='top' width={200} className='plan-title'>
                            </th>
                            <th valign='top' width={200} className='plan-title'>STOREFRONT
                                PARTNERZ
                            </th>
                            <th valign='top' width={200} className='plan-title smsize:hidden'>BRAND
                                PARTNERZ
                            </th>
                        </tr>
                        <tr>
                            <td className='text-[24px] smsize:text-[12px]'>
                                <div className='w-full flex justify-center items-center flex-col gap-2'>
                                    <div className='!leading-7 smsize:!leading-5'>
                                        Startz<br />
                                        <span className='whitespace-nowrap'>Free</span>
                                    </div>
                                    <Link to='/register-steps?plan=startz' className='w-[130px] smsize:w-[80px] smsize:text-[12px] smsize:px-1 smsize:h-[30px] flex px-5 cursor-pointer h-[40px] justify-center items-center rounded-full border  border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-[18px] text-base font-medium text-white hover:bg-white hover:text-fitzba-bright-gold-static uppercase'>Get Free</Link>
                                </div>
                            </td>
                            <td className='text-[24px] smsize:text-[12px]'>
                                <div className='w-full flex justify-center items-center flex-col gap-2'>
                                    <div className=' !leading-3'>
                                        <div className='!leading-7 smsize:!leading-5'>
                                            Sellz<br />
                                            $99/month<br />
                                        </div>
                                        <span className='whitespace-nowrap text-[14px] smsize:hidden'>(per storefront)</span>
                                    </div>
                                    <Link to='/register-steps?plan=sellz' className='w-[130px] smsize:w-[80px] smsize:text-[12px] smsize:px-1 smsize:h-[30px] flex px-5 cursor-pointer h-[40px] justify-center items-center rounded-full border  border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-[18px] text-base font-medium text-white hover:bg-white hover:text-fitzba-bright-gold-static uppercase'>Get Sellz</Link>
                                </div>
                            </td>
                            <td className='text-[24px] smsize:text-[12px] smsize:hidden'>
                                <div className='w-full flex justify-center items-center flex-col gap-2'>
                                    <div className='!leading-7 smsize:!leading-5'>
                                        <span>Let’s Chat!<br />
                                            book a call?</span>
                                    </div>
                                    <button onClick={() => { setIsOpen(true) }} className='w-[130px] smsize:w-[80px] smsize:text-[12px] smsize:px-1 smsize:h-[30px] flex px-5 cursor-pointer h-[40px] justify-center items-center rounded-full border  border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-[18px] text-base font-medium text-white hover:bg-white hover:text-fitzba-bright-gold-static uppercase whitespace-nowrap'>Book Now</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Business Profile on Fitzba</strong><br />
                                Tell consumers about yourself<br />
                                and the products and/or services you sell<br />
                                Display hours & location, logo, website link, and product categories
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Gift Card button</strong><br />
                                Sell Gift Cards online
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Checkout button</strong><br />
                                Accept payments<br />
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Reserve button</strong><br />
                                Accept reservations<br />
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Inventory for Retail</strong><br />
                                Manual/Upload/Live**<br />
                                (*setup required)
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'> </td>
                        </tr>
                        {/* <tr>
                            <th className='left'><strong>Menus for Services</strong><br />
                                Manual/Upload
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Menus for Restaurants</strong><br />
                                Manual/Upload

                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr> */}
                        <tr>
                            <th className='left'><strong>Promoted items</strong><br />
                                Up to 12 per month
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Special Offers</strong><br />
                                Offer coupons to drive sales
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Feature in Shopping Guides</strong><br />
                                (Holiday, Winter, Spring/Summer, Fall)

                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Dashboard & Reporting</strong><br />
                                Account Management & Consumer Insights
                            </th>
                            <td> </td>
                            <td><PlanIcon type='yes' /></td>
                            <td className='smsize:hidden'><PlanIcon type='yes' /></td>
                        </tr>
                    </tbody>
                </table>
                <div className='text-left mt-3'>* Ask about Member Programs for Chambers of Commerce and Partners.</div>
            </div>
            {/* <div className='text-[20px]'>White glove account setup and one <strong className='text-[#0297A7] underline'>FREE</strong> month</div> */}
            <RetailButtons setIsOpen={setIsOpen} setIsOpenForm={setIsOpenForm} gap={
                isMobile ? '20px' : '60px'
            } />
        </div>
    )
}
