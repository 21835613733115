
import React from 'react'
import { useSelector } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import './Products.css'
// import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getStoreCategories } from '@services/api.business.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
// import ItemCard from '../../zearch/components/ItemCard'
import SectionTitle from './SectionTitle'
// const TestCategories = [
//     {
//         "id": 173524553,
//         "name": "E-Bikes",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578415994.png",
//     },
//     {
//         "id": 173504335,
//         "name": "Bikes",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578422570.png",
//     },
//     {
//         "id": 173518590,
//         "name": "Sale",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578427148.png",
//     },
//     {
//         "id": 173518589,
//         "name": "Clothing",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4562907643.jpg",
//     },
//     {
//         "id": 173518588,
//         "name": "Accessories",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578484234.png",
//     }
// ]
/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function StoreCategories({
    business,
    width,
    isMobile,
    setFilterCategoryCheckOne
}) {
    const location = useSelector(state => state.user.location)

    const storeCategoryRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getStoreCategories', location?.lat, location?.lng, business?._id],
        queryFn: () => getStoreCategories({
            lat: location?.lat,
            lng: location?.lng,
            business_id: business?._id,
            // is_recommended: true,
            is_image: 'true'
        }),
    })

    const onClickScrollBtn = (step) => {
        if (!storeCategoryRef.current)
            return
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            storeCategoryRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1000) {
                clearInterval(slideTimer)
            }
        }, 20)
    }

    if (isPending)
        return <LoadingSection text='Loading Store Categories' height='200px' />

    const storeCategories = data?.data || []
    if (storeCategories.length === 0)
        return null

    return (
        <section
            style={{
                maxWidth: isMobile ? '100%' : `${width - 130}px`
            }}
            className="w-full flex flex-col relative"
        // className={`w-full relative`}
        >
            <SectionTitle title={'Categories'} />
            {/* <div className='relative'> */}
            <div className="w-full overflow-x-hidden smsize:overflow-x-auto flex gap-5 text-left scroll-wrap smsize:gap-3" ref={storeCategoryRef}>
                <span className='scroll-left' style={{ top: '85px' }} onClick={() => onClickScrollBtn(-50)}><AiOutlineLeft /></span>
                <span className='scroll-right' style={{ top: '85px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                {storeCategories?.map((category, index) => (
                    <div key={index} className='relative flex gap-5 w-[150px] h-[100px]'>
                        {category?.image ?
                            <div onClick={() => {
                                setFilterCategoryCheckOne({
                                    _id: category?._id,
                                    checked: true
                                })
                            }} key={index} className='relative flex gap-5 w-[150px] h-[100px] cursor-pointer'>
                                <img src={category?.image} className='w-[150px] h-[100px] rounded-xl object-cover' alt="" />
                                <div className='absolute bottom-0 left-0 right-0 bg-[#000000] bg-opacity-50 text-white text-center py-1 rounded-b-lg'>
                                    <span className='text-[14px]'>{category?.title}</span>
                                </div>
                            </div>
                            :
                            <div onClick={() => {
                                setFilterCategoryCheckOne({
                                    _id: category?._id,
                                    checked: true
                                })
                            }} key={index} className='h-[100px] w-[150px] px-1 cursor-pointer rounded-xl border flex items-center justify-center'>
                                <h3 className='text-center flex items-center justify-center leading-4'>{category?.title}</h3>
                            </div>
                        }
                    </div>
                ))}
            </div>
            {/* </div> */}
        </section>
    )
}
