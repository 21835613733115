import React, { useEffect } from 'react'
import { AiOutlineDollar } from "react-icons/ai"
import MobileTopFilterItem from './MobileTopFilterItem'
import { Drawer, Slider, Space, Input } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterPrice } from '@reducers/contentReducer'

export default function MobileTopFilterPrice() {
    const dispatch = useDispatch()
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    const [open, setOpen] = React.useState(false)
    const [priceRange, setPriceRange] = React.useState([0, 10000])
    const [priceRangeTemp, setPriceRangeTemp] = React.useState([0, 10000])

    useEffect(() => {
        setPriceRange([
            itemsFilter?.priceMin !== -1 ? itemsFilter?.priceMin : 0,
            itemsFilter?.priceMax !== -1 ? itemsFilter?.priceMax : 10000
        ])
    }, [open])

    function onClickViewResults() {
        dispatch(setFilterPrice({ priceMin: priceRange[0], priceMax: priceRange[1] }))
        setOpen(false)
    }

    return (
        <>
            <MobileTopFilterItem
                selectedable={true}
                firstIcon={<AiOutlineDollar />}
                text='Price'
                onClick={() => {
                    setOpen(true)
                }}
                isSelected={itemsFilter?.priceMin !== -1 || itemsFilter?.priceMax !== -1}
            />
            <Drawer
                title={''}
                closeIcon={''}
                className='filter-mobile-drawer'
                placement={'bottom'}
                onClose={() => setOpen(false)}
                open={open}
                key={'mobile-filter-price'}
                height={'350px'}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                extra={
                    <div className='flex justify-end'>
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                }
                // extra={<NavZearchBar />}
                style={{
                    // height: '100vh',
                    textAlign: 'left',
                    overflowY: 'auto',
                    paddingBottom: 20,
                    // backgroundColor: '#202020',
                    borderRadius: '10px 10px 0 0',
                }}
                footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center text-white'
                    onClick={onClickViewResults}
                >
                    View results
                </button>}
            >
                <div className='flex flex-col gap-3 justify-between h-full'>
                    <div className='flex flex-col gap-3'>
                        <h3 className='text-lg text-center'>What's your price ranage?</h3>
                        <div className='flex items-center justify-center text-[25px]'>
                            <Space>
                                <Input
                                    prefix="$"
                                    style={{
                                        width: '100px'
                                    }}
                                    value={priceRange[0]}
                                    onChange={(e) => {
                                        console.log('e.target.value', e.target.value)
                                        // if (!e.target.value) {
                                        //     return
                                        // }
                                        setPriceRange([Number(e.target.value), priceRange[1]])
                                    }}
                                />
                                <span>-</span>
                                <Input
                                    prefix="$"
                                    style={{
                                        width: '100px'
                                    }}
                                    value={priceRange[1]}
                                    onChange={(e) => {
                                        // if (!e.target.value) {
                                        //     return
                                        // }
                                        setPriceRange([priceRange[0], Number(e.target.value)])
                                    }}
                                />
                            </Space>
                        </div>
                        <div className='p-5'>
                            <Slider
                                range={{
                                    draggableTrack: true,
                                }}
                                step={50}
                                max={priceRangeTemp[1]}
                                min={priceRangeTemp[0]}
                                value={priceRange}
                                onChange={(value) => {
                                    setPriceRange(value)
                                }}
                                marks={{
                                    0: {
                                        style: {
                                            color: '#000',
                                        },
                                        label: <span>$0</span>,
                                    },
                                    5000: {
                                        style: {
                                            color: '#000',
                                        },
                                        label: <span>$5000</span>,
                                    },
                                    10000: {
                                        style: {
                                            color: '#000',
                                        },
                                        label: <span>$10000+</span>,
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className='text-center underline text-gray-400 cursor-pointer'
                        onClick={() => {
                            setPriceRange([0, 10000])
                        }}
                    >
                        Clear
                    </div>
                </div>
            </Drawer>
        </>
    )
}
