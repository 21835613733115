import React from 'react'
// import { IoCalendar, IoTime, IoLocationSharp } from 'react-icons/io5'
// import Iframe from 'react-iframe'
import ActiveCampaignForm from './components/ActiveCampaignForm'
import HeaderEvent0331 from './components/HeaderEvent0331'
import { Helmet, HelmetProvider } from 'react-helmet-async'
const LogoCard = ({ logo, title, description }) => {
  return (
    <div className='flex h-[200px] flex-col justify-between gap-5'>
      <img className='w-[250px]' src={logo} alt={title} />
      <div className='flex flex-col gap-3'>
        <p
          className='text-[16px] font-bold uppercase text-[#0b6186]'
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        <p className='text-[16px] text-[#020202]'>{description}</p>
      </div>
    </div>
  )
}

export default function Event0331() {
  return (
    <div className='bg-gray-100'>
      <HelmetProvider>
        <Helmet>
          <title>Consumer Retailer Conversation 20250331</title>
        </Helmet>
      </HelmetProvider>
      <div className='m-auto flex w-[1200px] flex-col items-center justify-center smsize:w-full'>
        <HeaderEvent0331 />
        <div className='flex w-full justify-between bg-black text-white smsize:flex-col'>
          <div className='smsize:max-h-none w-[50%] px-14 py-10 text-left smsize:w-full smsize:p-5'>
            <h1 className='text-[35px] font-bold leading-[32px] smsize:text-[30px] smsize:leading-7'>
              What It Really Means to Buy Canadian: A Consumer & Retailer
              Conversation
            </h1>
            <p className='mt-2 text-[16px] font-bold uppercase'>
              Buying Canadian is More Than Just a Trend—It’s a Movement.
            </p>
            <ActiveCampaignForm />
            {/* <div className='w-full'>
              <div className='flex w-full flex-col gap-3 py-5'>
                <div>
                  <div className='flex items-center justify-between'>
                    <p className='flex items-center justify-center gap-2'>
                      <IoCalendar />
                      Date: March 31
                    </p>
                    <p className='flex items-center justify-center gap-2'>
                      <IoTime />
                      Time: 6-8 PM
                    </p>
                  </div>
                  <div className='flex items-center justify-between'>
                    <p className='flex items-center justify-center gap-2'>
                      <IoLocationSharp />
                      Location: Found Books & Shop, Cochrane
                    </p>
                  </div>
                </div>
                <div className='flex flex-col gap-5'>
                  <div className='flex justify-between gap-5'>
                    <input
                      className='w-full rounded-lg border-[1px] border-[#ea0e8b] px-2 py-2 text-[16px]'
                      type='text'
                      placeholder='Full name'
                    />
                    <input
                      className='w-full rounded-lg border-[1px] border-[#ea0e8b] px-2 py-2 text-[16px]'
                      type='text'
                      placeholder='Your email'
                    />
                  </div>
                  <button className='w-[150px] rounded-full bg-[#ea0e8b] py-2 px-3 text-[16px] uppercase'>
                    Save my seat
                  </button>
                </div>
              </div>
              <p className='text-[#ea0e8b]'>
                This is a free event, but space is limited!. Please register to
                save your seat
              </p>
            </div> */}
          </div>
          <div className='w-[50%] smsize:w-full'>
            <img className='w-full' src='/event/event_1.png' alt='event0331' />
          </div>
        </div>
        <div className='flex w-full justify-between bg-black text-white smsize:flex-col-reverse'>
          <div className='w-[50%] smsize:w-full'>
            <img className='w-full' src='/event/event_2.png' alt='event0331' />
          </div>
          <div className='smsize:max-h-none flex max-h-[400px] w-[50%] flex-col gap-5 py-[80px] px-[90px] text-left smsize:w-full smsize:p-5'>
            <h1 className='text-[45px] leading-[45px] smsize:text-[30px] smsize:leading-7'>
              What We’ll
              <span className='hidden smsize:inline'> </span>
              <br className='smsize:hidden' />
              Discuss:
            </h1>
            <ul className='flex list-inside list-disc flex-col gap-3 p-5 text-[16px] smsize:p-0 smsize:text-[14px]'>
              <li>
                How small businesses source products & why “Made in Canada”
                isn’t always an option
              </li>
              <li>
                The challenges of competing with big-box retailers & online
                giants
              </li>
              <li>
                The true impact of consumer buying choices on local businesses
              </li>
              <li>
                Practical ways to support independent retailers during this
                challenging time
              </li>
            </ul>
            <div>
              <button
                onClick={() => {
                  // navigate to id=_form_67
                  document
                    .getElementById('_form_67')
                    .scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}
                className='rounded-full bg-[#ea0e8b] py-4 px-7 text-[16px] uppercase smsize:py-2 smsize:px-5'
              >
                Register now
              </button>
            </div>
          </div>
        </div>
        <div className='flex w-full justify-between bg-black text-white smsize:flex-col smsize:py-3'>
          <div className='smsize:max-h-none flex max-h-[400px] w-[50%] flex-col gap-5 py-[80px] px-[90px] text-left smsize:w-full smsize:gap-3 smsize:p-5'>
            <h1 className='px-5 text-[45px] leading-[45px] smsize:px-2 smsize:text-[30px] smsize:leading-7'>
              Why Attend?
            </h1>
            <ul className='flex flex-col gap-3 p-5 text-[16px] smsize:p-2 smsize:text-[14px]'>
              <li className='flex items-start'>
                &#10003; Learn directly from business owners & industry experts
              </li>
              <li className='flex items-start'>
                &#10003; Understand how global & local economic shifts impact
                small businesses
              </li>
              <li className='flex items-start'>
                &#10003; Ask questions & engage in a meaningful conversation
              </li>
              <li className='flex items-start'>
                &#10003; Discover actionable ways to support independent
                retailers
              </li>
              <li>
                Your choices matter. Join us for this important discussion and
                be part of the movement.
              </li>
            </ul>
            <div className='flex items-center justify-center'>
              <button
                onClick={() => {
                  // navigate to id=_form_67
                  document
                    .getElementById('_form_67')
                    .scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}
                className='rounded-full bg-[#ea0e8b] py-4 px-7 text-[16px] uppercase smsize:py-2 smsize:px-5'
              >
                Reserve my spot
              </button>
            </div>
          </div>
          <div className='w-[50%] smsize:w-full'>
            <img className='w-full' src='/event/event_3.png' alt='event0331' />
          </div>
        </div>
        <div className='flex w-full flex-col items-start justify-start gap-[60px] bg-white py-[100px] px-[120px] text-left smsize:gap-5 smsize:p-5'>
          <h2 className='w-full text-[45px] smsize:text-[22px] smsize:leading-6'>
            Brought to you in collaboration with:{' '}
          </h2>
          <div className='flex w-full items-center justify-between gap-[50px] text-[14px] smsize:flex-col smsize:gap-5'>
            <LogoCard
              logo='/event/event_logo_fitzba.png'
              title='Fitzba'
              description={`The 'shop local' app`}
            />
            <LogoCard
              logo='/event/event_logo_cfib.png'
              title='cfib'
              description={`In business for your business`}
            />
            <LogoCard
              logo='/event/event_logo_chamber.png'
              title={`Cochrane and District<br/>Chamber of Commerce`}
              description={`Your local business voice`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
