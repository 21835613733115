import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import FilterSideSearchBar from '../../zearch/components/FilterSideSearchBar'
import FilterSideSectionHeader from '../../zearch/components/FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'
import { getStoreBrandList } from 'services/api.business.service'
import { setFilterBrand } from '@reducers/contentReducer'

const DEFAULT_SHOW_NUMBER = 10

export default function FilterSideBrandDrawer({
  business_id,
  itemsFilter,
  dispatch,
  setFilterBrandCheckOne,
  color = '#FFF',
  showHeader = true
}) {

  const [input, setInput] = useState('')
  // const [isOpen, setIsOpen] = useState(false)
  // const isOpen = useSelector(state => state.user.filterState.brand)
  const isOpen = true
  const isMobile = useSelector(state => state.content.isMobile)
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'brand', value }))
  }
  const [showNumber, setShowNumber] = useState(DEFAULT_SHOW_NUMBER)
  const [brands, setBrands] = useState([])
  const [allCheckbox, setAllCheckbox] = useState(false)
  const [isRefresh, setIsRefresh] = useState(true)

  useEffect(() => {
    if (!isRefresh)
      return
    async function fetchData() {
      const res = await getStoreBrandList({
        storeid: business_id
      })
      console.log('getStoreBrandList:', res)
      setBrands(res.data || [])
      setIsRefresh(false)
      setShowNumber(DEFAULT_SHOW_NUMBER)
      // setShowNumber(res.data.length || DEFAULT_SHOW_NUMBER)
    }
    fetchData()
  }, [business_id, dispatch, isRefresh])


  function onClickTitle() {
    // defaultSetting()
    setIsOpen(!isOpen)
  }

  function defaultSetting() {
    setInput('')
    setShowNumber(DEFAULT_SHOW_NUMBER)
    setBrands([])
    setIsRefresh(true)
  }

  const handleChange = debounce((value) => {
    console.log('onSearch', value)
    // search function
    const findList = brands.filter(item => {
      // startswith or each words startswith if the name is contain splace
      if (!item.title)
        return false
      const words = item.title.indexOf(' ') !== -1 ? item.title.toLowerCase().split(' ') : [item.title.toLowerCase()]
      const inputLower = value.toLowerCase()
      const result = words.find(item => item.indexOf(inputLower) !== -1)
      // console.log('result', result)
      return result
    })
    setShowNumber(brands?.length)
    setBrands(findList)
  }, 600)

  const onSearch = (e) => {
    const value = e.target.value
    setInput(value)
    if (!value) {
      defaultSetting()
      // setIsRefresh(true)
      return
    }
    handleChange(value)
  }

  function onClickAllCheckbox(checked) {
    console.log('onClickAllCheckbox', checked)
    // const checked = e.target.checked
    if (checked) {
      // select all
      const ids = brands?.map(item => item._id)
      dispatch(setFilterBrand({ id: ids, checked: true }))
    } else {
      // deselect all
      // const ids = filterList?.brands.map(item => item._id)
      dispatch(setFilterBrand(null))
    }
    setAllCheckbox(checked)
  }

  return (
    <div className='filter-item' style={{
      color: color
    }}>
      <FilterSideSearchBar
        input={input}
        isOpen={isOpen}
        onSearch={onSearch}
        onClear={defaultSetting}
        allCheckbox={allCheckbox}
        onSelectAll={() => onClickAllCheckbox(!allCheckbox)}
        onClearAll={() => onClickAllCheckbox(false)}
      />
      <div className={`filter-item-list smsize:gap-2 smsize:text-[16px]`} style={{ display: isOpen ? 'flex' : 'none' }}>
        {brands?.slice(0, showNumber)?.map((brand) => {
          if (!brand._id || !brand?.title)
            return null
          return (
            <div key={brand._id} className='filter-checkbox-item' style={{
              color: color
            }}>
              <label>
                <input
                  value={brand._id}
                  type='checkbox'
                  className={`custom-checkbox`}
                  checked={itemsFilter?.brands.includes(brand._id)}
                  onChange={e => {
                    setFilterBrandCheckOne({
                      _id: brand._id,
                      checked: e.target.checked,
                    })
                  }}
                />
                <span className={`truncate ${isMobile && 'text-[16px]'}`}>{brand.title}</span>
              </label>
            </div>
          )
        })}
      </div>
      {/* {filterList?.brands.length > DEFAULT_SHOW_NUMBER && */}
      {((input !== '' && brands.length > DEFAULT_SHOW_NUMBER) || (input === '' && brands.length > DEFAULT_SHOW_NUMBER)) &&
        <div className='filter-checkbox-item underline' style={{ display: isOpen ? 'flex' : 'none' }}>
          <span onClick={() => setShowNumber(showNumber === DEFAULT_SHOW_NUMBER ? brands.length : DEFAULT_SHOW_NUMBER)}>
            Show {showNumber === DEFAULT_SHOW_NUMBER ? 'More' : 'Less'} Brands ({brands.length})
          </span>
        </div>
      }
    </div>
  )
}
