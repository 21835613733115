import React from 'react'
import { FiPhoneCall } from 'react-icons/fi'
import { GoHome } from 'react-icons/go'
import { GoLink } from "react-icons/go"
import { AiOutlineLeft } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { IoTimeOutline } from 'react-icons/io5'
import { FaHeart, FaRegHeart } from "react-icons/fa"
import { MdOutlineMessage } from 'react-icons/md'
import { PiShareFatLight } from 'react-icons/pi'
import { BsPatchCheckFill, BsCheck } from 'react-icons/bs'
import StoreAbout from './StoreAbout'
import StoreAboutFree from './StoreAboutFree'
import { useSelector, useDispatch } from 'react-redux'
import StoreMain from './StoreMain'
import StoreMainDemo from './StoreMainDemo'
import StoreMedia from './StoreMedia'
import { useQuery } from '@tanstack/react-query'
import { getStoreProfile } from '@services/api.service'
import LoadingSection from '@components/LoadingSection'
import copy from 'copy-to-clipboard'
import { message, Drawer, Popover } from 'antd'
import MessageForm from './components/MessageForm'
import {
    setStoreProfileFilterChanged,
    // setStoreProfileFilter,
    setItemsFilter,
    setStoreProfileList,
    setFilterStore,
    setSearchFilter
} from '@reducers/contentReducer'
import { setUserinfo } from 'reducers/userReducer'
import { addFavoriteStores } from '@services/api.user.service'
import { DownOutlined } from '@ant-design/icons'
import StoreOpenHours from '../itemDetail/components/StoreOpenHours'
import { HelmetProvider, Helmet } from "react-helmet-async"
import { Config } from '@Config'
// import ItemDeliveryIcon from '../itemDetail/components/ItemDeliveryIcon'
// import { HiOutlineLocationMarker, HiOutlineTruck } from 'react-icons/hi'
// import { FiPackage } from 'react-icons/fi'
import { FaCanadianMapleLeaf } from "react-icons/fa"
// import { IoChatbubbleEllipsesOutline } from "react-icons/io5"
// import StoreGiftGuide from './StoreGiftGuide'
// import { IoStorefront } from "react-icons/io5"
// import { FaShopify } from "react-icons/fa"
// import { IoBagHandleSharp } from "react-icons/io5"
// import { AiOutlineSchedule } from "react-icons/ai"
import StoreGiftCard from './StoreGiftCard'
import { GrSync } from "react-icons/gr"
import YoutubeEmbed from './components/YoutubeEmbed'
import YoutubeDemoList from './components/YoutubeDemoList'
import StoreIcons from './components/StoreIcons'
import { getYoutubeVideoId } from '@utils/Helper'
import MobileTopFilterStoreProfile from 'pages/zearch/components/MobileTopFilterStoreProfile'
import InstagramDynamicEmbed from './components/InstagramDynamicEmbed'
// import InstagramEmbed2 from './components/InstagramEmbed2'
// const tabs = ['shop', 'about', 'media']
// const tabs = ['shop', 'about']

export default function StoreProfile({
    storeId
}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMobile = useSelector(state => state.content.isMobile)
    const userinfo = useSelector(state => state.user.userinfo)
    const location = useSelector(state => state.user.location)
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    // const [business, setBusiness] = React.useState(null)
    const [isOpenMessage, setIsOpenMessage] = React.useState(false)
    const [tab, setTab] = React.useState('shop')
    const [tabs, setTabs] = React.useState(['shop', 'about'])


    const {
        isPending,
        // error,
        data: business,
    } = useQuery({
        queryKey: ['storeProfile', storeId, location?.lat, location?.lng],
        queryFn: async () => {
            const res = await getStoreProfile(storeId, {
                lat: location?.lat,
                lng: location?.lng,
            })
            console.log('getStoreProfile', res)
            dispatch(setStoreProfileList(null))
            if (res?.hasGiftCard) {
                setTabs(['shop', 'gift card', 'about'])
            }
            // dispatch(setItemsFilter(null))
            return res
        },
    })

    if (isPending) return <LoadingSection text='Loading Store Profile' height={'200px'} />
    // if (error) {
    //     console.error('Error loading store profile', error)
    //     return 'Loading Store Profile Error'
    // }

    console.log('business', business)
    console.log('itemsFilter', itemsFilter)
    // get today's openhours from business.openHours
    // get the today shortname, like Mon, Tue, Wed, etc
    function getTodayShortDayName(locale = 'en-CA') {
        const formatter = new Intl.DateTimeFormat(locale, { weekday: 'short' })
        return formatter.format(new Date())
    }
    console.log('Today is ', getTodayShortDayName())
    const openHour = business.openHours.find(h => h.dayStart === getTodayShortDayName())
    console.log('openHour', openHour)

    let currentStatus = <span className='text-red-500'>Closed</span>
    if (openHour && openHour?.openAt && openHour?.closeAt) {
        const openAt = parseInt(openHour.openAt?.split(':')[0])
        const closeAt = parseInt(openHour.closeAt?.split(':')[0])
        const currentHour = new Date().getHours()
        if (currentHour >= openAt && currentHour < closeAt) {
            currentStatus = <span className='text-green-500'>Opened</span>
        }
    }

    const isFavorited = userinfo?.favoriteStores?.includes(business._id)

    const handleAddOrRemoveFavorite = async () => {
        if (!userinfo) {
            message.error('Please login to add to favorite')
            return
        }
        try {
            const res = await addFavoriteStores({
                storeIds: [business._id],
                action: userinfo?.favoriteStores?.includes(business._id) ? 'remove' : 'add'
            })
            console.log('handleAddOrRemoveFavorite', res)
            dispatch(setUserinfo({
                ...userinfo,
                favoriteStores: res.data
            }))
            message.success('Operation successful')
        } catch (error) {
            console.error('addFavoriteStores', error)
        }
    }

    const onClickBackToSearch = () => {
        dispatch(setSearchFilter({
            type: 'stores',
            value: []
        }))
        // navigate(-1)
    }

    const mapUrl = business?.mapUrl ? business.mapUrl : `https://maps.google.com/?q=${business?.lat},${business?.lng}`
    const youtubeVideo = getYoutubeVideoId(business?.videoLink)
    console.log('youtubeVideo', youtubeVideo)
    return (
        <div className='w-full'>
            {/* <InstagramEmbed2 /> */}
            {/* <InstagramDynamicEmbed postUrl={'https://www.instagram.com/p/DG3-LeJtlDE/'} /> */}
            {isMobile && <MobileTopFilterStoreProfile business_id={business?._id} isStoreProfile={true} />}
            <HelmetProvider>
                <Helmet>
                    <title>{business?.name} - {Config.appName}</title>
                </Helmet>
            </HelmetProvider>
            <Drawer
                title="Send a message"
                placement="right"
                closable={true}
                onClose={() => setIsOpenMessage(false)}
                open={isOpenMessage}
                width={isMobile ? '100%' : '500px'}
                style={{ zIndex: 999999 }}
                zIndex={999999}
            >
                <MessageForm business={business} userinfo={userinfo} />
            </Drawer>
            {business.banner &&
                <div>
                    <img src={business.banner} alt={business.name} className='w-full h-[330px] bg-no-repeat bg-center smsize:bg-cover smsize:h-[180px]' />
                </div>
            }
            <Link
                onClick={onClickBackToSearch}
                to='/search'
                className='p-[10px] w-fit cursor-pointer items-center justify-center gap-[5px] text-[16px] uppercase text-[#727272] flex smsize:pt-[70px]'
            >
                <AiOutlineLeft /> Back to search
            </Link>
            <div className='m-auto w-full flex px-10 pb-5 justify-between gap-10 smsize:gap-5 smsize:flex-col smsize:p-3 smsize:relative shadow'>
                <div className='flex items-start justify-center smsize:justify-center smsize:items-center flex-col gap-3 '>
                    <div className='flex flex-col gap-10 smsize:gap-5 justify-center items-center'>
                        <div className='relative bg-white w-[185px] h-[185px] rounded-full border-2 border-[#E4B456] flex items-center justify-center'>
                            {business.logo ?
                                <img src={business?.logo} alt={business.name} className=' w-[182px] h-[182px] rounded-full object-contain' />
                                :
                                <div className='text-[20px]'>{business.name}</div>
                            }
                            <FaCanadianMapleLeaf className='absolute top-[0px] right-0 w-[25px] h-[25px] bg-white rounded-full text-[#ff0000]' />
                        </div>
                        <div className='flex gap-3 justify-between smsize:flex-col'>
                            <div className='flex gap-3'>
                                {userinfo &&
                                    <button onClick={handleAddOrRemoveFavorite} className='min-w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]'>
                                        {isFavorited ?
                                            <>
                                                <FaHeart className='text-red-500' size={20} />
                                                Followed
                                            </>
                                            :
                                            <>
                                                <FaRegHeart size={20} />
                                                Favourite
                                            </>
                                        }
                                    </button>
                                }
                                <button onClick={() => setIsOpenMessage(true)} className='min-w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]'>
                                    <MdOutlineMessage size={20} />Message
                                </button>
                                <button className='min-w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]' onClick={() => {
                                    const url = window.location.href
                                    copy(`${business?.name} ${url}`)
                                    console.log('Copied to clipboard:', url)
                                    message.success('Store profile link copied')
                                }}>
                                    <PiShareFatLight size={20} />Share
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex-1 flex flex-col text-[14px] smsize:text-[13px] font-light text-left gap-4'>
                    <h1 className='text-[48px] font-medium smsize:text-[25px] smsize:leading-6'>
                        {business?.name}
                    </h1>
                    {/* {business?.shortDesc && isMobile &&
                        <div className='text-left line-clamp-5'>{business?.shortDesc}</div>
                    } */}
                    {(business.isVerified || business.isLiveInventory) &&
                        <div className='flex gap-5'>
                            {business.isVerified &&
                                <div className='flex gap-2 text-[16px] items-center justify-center'>
                                    <BsPatchCheckFill size={24} />
                                    <h3>Verified Store</h3>
                                </div>
                            }
                            {business.isLiveInventory &&
                                <div className='flex gap-2 text-[16px] items-center justify-center'>
                                    <GrSync size={24} />
                                    <h3>Live Inventory</h3>
                                </div>
                            }
                        </div>
                    }
                    <div className='flex gap-2 items-center'>
                        <FiPhoneCall /><strong> Phone:</strong>
                        <a className='text-[#202020] underline smsize:text-[14px]' href={`tel:${business?.businessPhone}`}>
                            {business?.businessPhone}
                        </a>
                    </div>
                    <div className='flex gap-2 items-start'>
                        <GoHome /><strong> Address:</strong>
                        <a href={mapUrl} target='_blank' rel='noopener noreferrer' className=' text-[#202020] underline smsize:text-[14px]'>
                            {business?.address}, {business?.city}, {business?.province} {business?.postalCode}
                        </a>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <IoTimeOutline />
                        <strong> Hours:</strong>
                        <Popover
                            content={<StoreOpenHours openHours={business.openHours} />}
                            title="Open Hours"
                            trigger="hover"
                            placement="bottom"
                        >
                            <div>
                                {currentStatus}{' '}
                                {openHour && openHour?.openAt && openHour?.closeAt &&
                                    `${openHour?.dayStart}: ${openHour?.openAt} - ${openHour?.closeAt}`}
                                {' '}
                                <DownOutlined />
                            </div>
                        </Popover>
                    </div>
                    {business?.website &&
                        <div className='flex gap-2 items-start'>
                            <GoLink /><strong> Website:</strong>
                            <a href={business?.website} target='_blank' rel='noopener noreferrer' className=' text-[#202020] underline smsize:text-[14px]'>
                                {business.website}
                            </a>
                        </div>
                    }
                    <StoreIcons business={business} extraClass='gap-[15px]' />
                </div>
                <div className='flex flex-col gap-3 smsize:w-full'>

                    {youtubeVideo &&
                        <div className='w-full flex items-center justify-center'>
                            {youtubeVideo?.type === 'playlist' ?
                                <YoutubeDemoList
                                    playlistId={youtubeVideo?.id}
                                    width={isMobile ? '100%' : '400'}
                                    height={isMobile ? '500' : '300'}
                                    extraClass='w-full'
                                />
                                :
                                <YoutubeEmbed
                                    extraClass='w-full'
                                    videoId={youtubeVideo?.id}
                                    width={isMobile ? '100%' : '400'}
                                    height={isMobile ? youtubeVideo?.type === 'shorts' ? '500' : '300' : '300'}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
            {
                business?.memberType === 'startz' ?
                    <StoreAboutFree isMobile={isMobile} business={business} location={
                        {
                            lat: business?.lat,
                            lng: business?.lng
                        }
                    } />
                    :
                    <>
                        <div className='w-full bg-[#F4F4F4] flex items-center justify-center pt-2 gap-14 text-[16px] font-semibold'>
                            {tabs.map((t, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        setTab(t)
                                        dispatch(setStoreProfileFilterChanged(true))
                                    }}
                                    className={`cursor-pointer leading-9 px-3 ${tab === t ? 'border-b-[3px] border-[#E4B456]' : ''}`}>
                                    {t}
                                </div>
                            ))}
                        </div>
                        {tab === 'shop' && <StoreMainDemo isMobile={isMobile} business={business} location={location} />}
                        {tab === 'about' && <StoreAbout isMobile={isMobile} business={business} location={
                            {
                                lat: business?.lat,
                                lng: business?.lng
                            }
                        } />}
                        {tab === 'media' && <StoreMedia isMobile={isMobile} business={business} location={location} />}
                        {tab === 'gift card' && <StoreGiftCard isMobile={isMobile} business={business} location={location} />}
                        {/* {tab === 'gift guide' && <StoreGiftGuide isMobile={isMobile} business={business} location={location} />} */}
                    </>
            }
        </div >
    )
}
