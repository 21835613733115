/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * MobileFilter.js contains the filter component for mobile view. It provides options to filter by location, category, store, brand, delivery, price, and condition.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 4th, 2024
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilterPrice,
  // setFilterDistance,
  // setFilterStore,
  // setFilterBrand,
  setFilterDeliveryTypes,
  setSearchFilter
  // setFilterCondition
} from '@reducers/contentReducer'
import FilterSideCategory from '../../store/components/FilterSideCategory'
import FilterSidePrice from './FilterSidePrice'
// import FilterSideStore from './FilterSideStore'
import FilterSideBrand from '../../store/components/FilterSideBrand'
// import FilterSideLocation from './FilterSideLocation'
import FilterSideDelivery from './FilterSideDelivery'
// import FilterSideCondition from './FilterSideCondition'
import '../Zearch.css'
import { Drawer } from 'antd'
// import FilterOpenIcon from './FilterOpenIcon'
import { CloseOutlined } from '@ant-design/icons'
import MobileTopFilterItem from './MobileTopFilterItem'
import { BiMenuAltRight } from "react-icons/bi"

export default function MobileTopFilterAllStoreProfile({
  business_id
}) {
  const isMobile = true
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  const filterList = useSelector(state => state.content.filterList)

  // Handle price filter change
  const onClickPrice = (min, max) => {
    dispatch(setFilterPrice({ priceMin: min, priceMax: max }))
  }

  const setFilterBrandCheckOne = (value) => {
    console.log('setFilterBrandCheckOne value', value)
    dispatch(setSearchFilter({
      type: 'brands',
      value: value.checked
        ? [...itemsFilter.brands, value._id]
        : itemsFilter.brands.filter(item => item !== value._id)
    }))
  }

  console.log('MobileTopFilterAllStoreProfile111')

  return (
    <>
      <MobileTopFilterItem
        selectedable={true}
        text='Sort & Filter'
        onClick={() => {
          setOpen(true)
        }}
        firstIcon={<BiMenuAltRight size={16} />}
      // isSelected={itemsFilter?.condition?.length > 0}
      />
      <Drawer
        title={<h4 style={{ color: '#fff' }}>Filter</h4>}
        closeIcon={<CloseOutlined style={{ color: '#fff', }} />}
        className='filter-mobile'
        placement={'bottom'}
        onClose={() => setOpen(false)}
        open={open}
        key={'filter'}
        height={'80%'}
        styles={{
          body: {
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
        // extra={<NavZearchBar />}
        style={{
          // height: '100vh',
          textAlign: 'left',
          overflowY: 'auto',
          paddingBottom: 20,
          backgroundColor: '#202020',
        }}
        footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center'
          onClick={() => {
            setOpen(false)
          }}
        >
          APPLY
        </button>}
      >
        <FilterSideCategory
          dispatch={dispatch}
          itemsFilter={itemsFilter}
          isMobile={isMobile}
          business_id={business_id}
          color={'#fff'}
          bgColor='#202020'
          isDrawer={false}
        />

        <FilterSideBrand
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterBrandCheckOne={setFilterBrandCheckOne}
          filterList={filterList}
          isMobile={isMobile}
          business_id={business_id}
          color={'#FFF'}
        />
        <FilterSideDelivery
          setFilterDeliveryTypes={setFilterDeliveryTypes}
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          isMobile={isMobile}
        />
        <FilterSidePrice
          onClickPrice={onClickPrice}
          itemsFilter={itemsFilter}
          dispatch={dispatch}
          setFilterPrice={setFilterPrice}
          isMobile={isMobile}
        />
      </Drawer >
    </>
  )
}
