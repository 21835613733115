/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideSelectedMobile.js implements functionality for displaying selected filter options at the top of the screen
 * For mobile devices 
 * 
 * @author Yang Ming
 * @version February 12th, 2024 
 */

import React from 'react'
import MobileTopFilterPrice from './MobileTopFilterPrice'
import MobileTopFilterOnsale from './MobileTopFilterOnsale'
// import MobileTopFilterCondition from './MobileTopFilterCondition'
// import MobileTopFilterDistance from './MobileTopFilterDistance'
import MobileTopFilterCategoryStoreProfile from './MobileTopFilterCategoryStoreProfile'
import MobileTopFilterBrandStoreProfile from './MobileTopFilterBrandStoreProfile'
// import MobileTopFilterStore from './MobileTopFilterStore'
// import MobileTopFilterReserve from './MobileTopFilterReserve'
import { useDispatch } from 'react-redux'
import { setSearchInput } from '@reducers/contentReducer'
import { initItemsFilter } from '@reducers/contentReducer'
import { useNavigate } from 'react-router-dom'
import MobileTopFilterAllStoreProfile from './MobileTopFilterAllStoreProfile'
import '../Zearch.css'

export default function MobileTopFilterStoreProfile({
  business_id
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onClearAll = () => {
    dispatch(setSearchInput(''))
    dispatch(initItemsFilter(null))
  }

  return (
    <>
      <div className={`relative filter-top-mobile flex w-full`}>
        <div className='w-full flex gap-2 flex-nowrap overflow-x-auto'>
          <MobileTopFilterAllStoreProfile business_id={business_id} />
          <MobileTopFilterCategoryStoreProfile business_id={business_id}/>
          <MobileTopFilterBrandStoreProfile business_id={business_id}/>
          <MobileTopFilterPrice />
          <MobileTopFilterOnsale />
          <div className={`filter-selected-clear-all`} onClick={onClearAll}>
            Clear All
          </div>
        </div>
      </div>
    </>
  )
}
