import React from 'react'
import ItemDeliveryIcon from '../../itemDetail/components/ItemDeliveryIcon'
import { IoStorefront } from "react-icons/io5"
import { IoBagHandleSharp } from "react-icons/io5"
import { AiOutlineSchedule } from "react-icons/ai"
import { HiOutlineLocationMarker, HiOutlineTruck } from 'react-icons/hi'
import { FiPackage } from 'react-icons/fi'
import { FaCanadianMapleLeaf } from "react-icons/fa"

export default function StoreIcons({
    business,
    extraClass,
    isItemDetail = false
}) {
    return (
        <div className={`flex ${extraClass} items-start smsize:w-full smsize:overflow-x-auto smsize:gap-2`}>
            {!isItemDetail && (
                <>
                    {business?.isLocallyOwned &&
                        <ItemDeliveryIcon
                            text='Locally Owned'
                            Icon={HiOutlineLocationMarker}
                            deliveryTypes={['Locally Owned']}
                            tip='Locally Owned'
                        />
                    }
                    {business?.isCanadianItems &&
                        <ItemDeliveryIcon
                            text='Canadian Items'
                            Icon={FaCanadianMapleLeaf}
                            deliveryTypes={['Canadian Items']}
                            tip='Canadian Items'
                        />
                    }
                </>
            )}
            <ItemDeliveryIcon
                text='Pickup'
                Icon={IoStorefront}
                deliveryTypes={['Pickup']}
                tip='Pickup'
            />

            {(business?.reservationEmail || business?.canBeReserved) &&
                <ItemDeliveryIcon
                    text='Reserve'
                    Icon={AiOutlineSchedule}
                    deliveryTypes={['Reserve']}
                    tip='Reserve'
                />
            }
            {business?.isCheckout &&
                <ItemDeliveryIcon
                    text='Checkout'
                    Icon={IoBagHandleSharp}
                    deliveryTypes={['Checkout']}
                    tip='Checkout'
                />
            }
            {business?.isDelivery &&
                <ItemDeliveryIcon
                    text='Delivery'
                    Icon={HiOutlineTruck}
                    deliveryTypes={['Delivery']}
                    tip='Delivery'
                />
            }
            {business?.isShipping &&
                <ItemDeliveryIcon
                    text='Shipping'
                    Icon={FiPackage}
                    deliveryTypes={['Shipping']}
                    tip='Shipping'
                />
            }

        </div>
    )
}
