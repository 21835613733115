import { useEffect } from 'react'

const ActiveCampaignForm = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = '/event/form67.js' // 本地脚本
        script.charset = 'utf-8'
        script.async = true
        document.body.appendChild(script) // 确保脚本执行
    }, [])

    return <div id='_form_67' className="_form_67"></div> // 确保这个 div 存在
}

export default ActiveCampaignForm