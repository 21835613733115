/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * SearchPagination is a functional React component that renders
 * pagination for search results
 *
 * @author Yang Ming, James Nguyen
 * @version May 09, 2023
 */

import React, { useEffect, useState } from 'react'
import { setFilterPage } from '@reducers/contentReducer'
import ReactPaginate from 'react-paginate'
import { GoChevronLeft, GoChevronRight } from "react-icons/go"
import './SearchPagination.css'



/**
 *
 * @param itemList A list of itmes
 * @returns Render a pagination function that only allow a specific amount of
 *          item cards to display at one time
 */
export default function CollectionPagination({ itemsList, setCurrentPage }) {


  // If there are no items or only one page of items, do not render pagination
  if (!itemsList || itemsList.totalPage <= 1) {
    return null
  }

  return (
    <>
      <div className='flex w-full justify-center sm:justify-end pr-2 mb-[50px]'>
        <div className='pagination'>
          <ReactPaginate
            breakLabel={'...'}
            // breakLabel={screenWidth >= 450 ? '...' : ''}
            nextLabel={<GoChevronRight size={30} />}
            pageRangeDisplayed={2}
            marginPagesDisplayed={3}
            // marginPagesDisplayed={screenWidth >= 450 ? (screenWidth >= 980 ? 3 : 1) : 0}
            onPageChange={e => {
              setCurrentPage(e.selected + 1)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            pageCount={itemsList?.totalPage}
            previousLabel={<GoChevronLeft size={30} />}
            renderOnZeroPageCount={null}
            forcePage={itemsList?.currentPage - 1}
            containerClassName='h-5 w-5 phone:h-10 phone:w-10 border-2 border-opacity-10'
            activeClassName='active'
            disabledClassName='cursor-not-allowed'
            // previousClassName='-mx-2'
            previousClassName='pagination-prev'
            // nextClassName='-mx-2'
            nextClassName='pagination-next'
            pageClassName='pagination-page'
            activeLinkClassName='text-blue-500'
            breakClassName=''
          />
        </div>
      </div>
    </>
  )
}
