
import '../zearch/components/Filter.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  // setStoreProfileFilter
  setItemsFilter
} from '@reducers/contentReducer'
import FilterSideBrand from './components/FilterSideBrand'
import FilterSideDelivery from './components/FilterSideDelivery'
import FilterSideCategory from './components/FilterSideCategory'
// import FilterSideCondition from './components/FilterSideCondition'
import FilterSidePrice from './components/FilterSidePrice'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

export default function StoreFilter({
  business,
  isMobile,
  setFilterBrandCheckOne
}) {
  const dispatch = useDispatch()
  const filterList = useSelector(state => state.content.filterList)
  const storeProfileFilter = useSelector(state => state.content.itemsFilter)
  const [isOpen, setIsOpen] = React.useState(!isMobile)

  const setFilterPrice = (value) => {
    dispatch(setItemsFilter({
      ...storeProfileFilter,
      ...value
    }))
  }

  // const setFilterCondition = (value) => {
  //   dispatch(setItemsFilter({
  //     ...storeProfileFilter,
  //     condition: value.checked
  //       ? [...storeProfileFilter.condition, value.name]
  //       : storeProfileFilter.condition.filter(item => item !== value.name)
  //   }))
  // }

  const setFilterDeliveryTypes = (value) => {
    dispatch(setItemsFilter({
      ...storeProfileFilter,
      deliveryTypes: value.checked
        ? [...storeProfileFilter.deliveryTypes, value.name]
        : storeProfileFilter.deliveryTypes.filter(item => item !== value.name)
    }))
  }

  // const setFilterBrandCheckOne = (value) => {
  //   dispatch(setItemsFilter({
  //     ...storeProfileFilter,
  //     brands: value.checked
  //       ? [...storeProfileFilter.brands, value._id]
  //       : storeProfileFilter.brands.filter(item => item !== value._id)
  //   }))
  // }

  return (
    <div className={`flex-1 sticky top-[80px]  smsize:static smsize:top-0 h-screen smsize:h-auto smsize:w-full text-black`} id='filter-wrapper'>
      <div className='w-[300px] smsize:w-full smsize:px-5 smsize:min-h-0 mx-auto min-h-screen border-r border-[#eee] px-10 font-lexend text-[15px] filter-content text-left' id="zearch-filter">
        <div className='w-full filter-item mt-10'>
          <div className='flex items-center justify-between text-black'>
            <h3 className='text-[18px] font-[500]'>FILTERS</h3>
            {isOpen ?
              <SlArrowUp onClick={() => setIsOpen(!isOpen)} size={16} className='hidden smsize:block' /> :
              <SlArrowDown onClick={() => setIsOpen(!isOpen)} size={16} className='hidden smsize:block' />}
          </div>
        </div>

        <div className={`${isOpen ? 'block' : 'hidden'}`}>
          <FilterSideCategory
            dispatch={dispatch}
            itemsFilter={storeProfileFilter}
            isMobile={isMobile}
            business_id={business?._id}
            color={'#000'}
          />
          <FilterSideBrand
            itemsFilter={storeProfileFilter}
            dispatch={dispatch}
            setFilterBrandCheckOne={setFilterBrandCheckOne}
            filterList={filterList}
            isMobile={isMobile}
            business_id={business?._id}
            color={'#000'}
          />
          <FilterSideDelivery
            setFilterDeliveryTypes={setFilterDeliveryTypes}
            itemsFilter={storeProfileFilter}
            dispatch={dispatch}
            isMobile={isMobile}
          />
          <FilterSidePrice
            setFilterPrice={setFilterPrice}
            itemsFilter={storeProfileFilter}
            dispatch={dispatch}
            isMobile={isMobile}
          />
          {/* <FilterSideCondition
            setFilterCondition={setFilterCondition}
            itemsFilter={storeProfileFilter}
            dispatch={dispatch}
            isMobile={isMobile}
          /> */}
        </div>
      </div>
    </div>
  )
}
