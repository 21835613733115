import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Loading from '@components/Loading'
import CollectionCard from './components/CollectionCard'
import ShowMore from './components/ShowMore'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getRecommendedProducts } from '@services/api.service'
import LoadingSection from '@components/LoadingSection'
import ItemCard from '../zearch/components/ItemCard'
import { useSelector } from 'react-redux'
import { IoChevronBack } from "react-icons/io5"
import { Link } from 'react-router-dom'
import CollectionPagination from './components/CollectionPagination'
export default function CollectionDetail({
    collection,
    setCollection,
}) {

    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)
    const [currentPage, setCurrentPage] = React.useState(1)

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data: products,
    } = useQuery({
        queryKey: ['getRecommendedProducts', collection._id, currentPage],
        queryFn: () => getRecommendedProducts({
            recommendedId: collection._id,
            lat: location?.lat,
            lng: location?.lng,
            currentPage,
        }),
    })


    // console.log('collection', collection)
    const onClickSeemore = () => {
        console.log('onClickSeemore')
        setCurrentPage(currentPage + 1)
    }

    return (
        <div className='flex gap-3 flex-wrap'>
            <div className='w-full flex flex-col'>
                {/* <StoreListFilterSelected /> */}
                <Link className='flex gap-3 items-center justify-start p-5' onClick={() => setCollection(null)}>
                    <IoChevronBack /> Back to collections
                </Link>
                {collection?.banner && collection?.banner.indexOf('http') !== -1 &&
                    <div className='w-full min-h-[250px]'>
                        <img height={250} width={'100%'} src={collection.banner} alt={collection?.title} />
                    </div>
                }
                <div className='mt-3 flex px-[70px] gap-2 flex-col smsize:px-5 smsize:py-5'>
                    <h1 className='text-[25px] font-normal uppercase m-0 p-0'>{collection.title}</h1>
                    {collection.description && collection.description !== '<p><br></p>' &&
                        <p className='text-[16px]' dangerouslySetInnerHTML={{ __html: collection.description }}></p>
                    }
                    <div className='items-content-list'>
                        <div className='grid w-full grid-cols-2 gap-x-4 gap-y-5 sm:gap-x-8 md:grid-cols-4 3xl:grid-cols-5 3xl:gap-x-2'>
                            {/* <div className='py-5 flex gap-5 flex-wrap smsize:px-0 smsize:justify-center smsize:items-center smsize:gap-10'> */}
                            {products?.data?.map((item) => {
                                return <ItemCard key={item?._id} userinfo={userinfo} item={item} handleAddToCart={addToCart} />
                            })}
                        </div>
                    </div>
                    {/* </div> */}
                    <CollectionPagination itemsList={products} setCurrentPage={setCurrentPage} />
                </div>
            </div>
        </div>
    )
}
