import { useEffect } from 'react'
import { Modal } from 'antd'

const ActiveCampaignFormPopup = ({
    isOpen,
    setIsOpen
}) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = '/event/form67.js' // 本地脚本
        script.charset = 'utf-8'
        script.async = true
        document.body.appendChild(script) // 确保脚本执行
    }, [])

    return (
        <Modal
            // title="Get the app"
            open={isOpen}
            onCancel={() => {
                const date = new Date()
                // save today's date to localStorage
                localStorage.setItem('event0331', date.toDateString())
                setIsOpen(false)
            }}
            footer={null}
            forceRender
            destroyOnClose={false}
            width={550}
            zIndex={99999}
        >
            <div className='download-wrapper'>
                <div className='download-header'>
                    <img src="/event/event_logo_fitzba.png" width={250} alt="logo" />
                    {/* <h1>Get the fitzba app!</h1>
                    <p>Shopping local just got convenient</p> */}
                </div>
                <div id='_form_67' className="_form_67"></div>
            </div>
        </Modal>
    )
}

export default ActiveCampaignFormPopup