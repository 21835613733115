
import React from 'react'
import MyImage from '@components/MyImage'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import CanadianBrandIcon from 'pages/itemDetail/components/CanadianBrandIcon'

export default function BrandListCard({
  item,
  onClickBrand,
  toLink = '/search'
}) {

  return (
    <div className='flex flex-col items-center justify-center gap-3'>
      <div className=' relative text-center border-1 h-32 w-32 rounded-full border-black bg-white md:h-32 md:w-32 flex flex-col items-center justify-start'>
        {item?.country === 'CA' &&
          <CanadianBrandIcon size={20} top={2} right={2} />
        }
        <Link onClick={() => onClickBrand(item._id)} to={toLink}>
          {item?.image ? (
            <MyImage
              src={item?.image}
              className={`rounded-full border object-contain h-32 w-32 border-[#eee]`}
              alt={item?.title}
            />
          ) : (
            <Avatar
              className={`font-sbold mx-auto cursor-pointer break-words border border-[#eee] bg-fitzba-white-static align-middle font-anke text-fitzba-off-black-static`}
              size={128}
            >
              {item?.title}
            </Avatar>
          )}
        </Link>
      </div>
      {/* <Link Link to="/search" className=' leading-4 cursor-pointer break-words' onClick={() => onClickBrand(item._id)}>{item?.title}</Link> */}
    </div>
  )
}
