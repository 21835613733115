/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { getGiftCards } from '@services/api.business.service'
import { useQuery } from '@tanstack/react-query'
import GiftCardsItem from './GiftCardsItem'
import SectionTitle from './SectionTitle'

/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function GiftCards({
    business,
    isMobile
}) {


    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getGiftCards', business?._id],
        queryFn: () => getGiftCards({
            business_id: business?._id
        }),
    })

    if (isPending) return null

    const giftCards = data?.data || []
    console.log('giftCards', giftCards)

    // if (giftCards.length === 0) return null

    return (
        <section
            style={{
                maxWidth: '100%'
            }}
            className="w-full relative m-auto flex flex-col">
            <SectionTitle title={'Gift Cards'} />
            {giftCards.length === 0 && <div className="text-center">No Gift Cards Available</div>}
            <div className="w-full overflow-x-hidden smsize:overflow-x-auto flex gap-5 text-left scroll-wrap smsize:gap-3">
                {giftCards?.map((item, index) => (
                    <GiftCardsItem key={index} item={item} />
                ))}
            </div>
        </section>
    )
}
