/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * TierCard is a functional React component that displays a card for a specific tier.
 * It shows the tier title, content, and key points.
 * It also provides a "show more" button to reveal additional information on mobile devices.
 *
 * @author Yang Ming
 * @version November 10th, 2023
 */

import React from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { RxDot } from "react-icons/rx"
// import { Link } from 'react-router-dom'
/**
 * Displays a card for a specific tier.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.tier - The tier to display.
 * @param {boolean} props.isMobile - Whether the component is being viewed on a mobile device.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function TierCard({
  tier,
  isMobile,
  onSelectPlan
}) {
  const [isShowMore, setIsShowMore] = React.useState(false)

  const backgroundColor = tier.title === 'sellz' ? '#69BDC8' : '#e8e8e8'
  const titleColor = tier.title === 'sellz' ? '#fff' : '#000'
  function onClickShowMore() {
    setIsShowMore(!isShowMore)
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='relative flex w-[370px] smsize:h-fit smsize:w-full flex-col items-center rounded-3xl border-2 border-black'>
        <div className='flex flex-col items-center px-5 py-5 gap-10'>
          <div
            className={`w-[185px] text-white rounded-[50%] border-2 py-3 border-black text-[40px] font-medium`}
            style={{
              backgroundColor: backgroundColor,
              color: titleColor,
            }}
          >
            {tier.title}
          </div>
          <div className='mb-4 flex w-full flex-col gap-5 text-left text-[16px] font-light'>
            <div className='mb-4 flex w-full flex-col gap-5'>
              <p dangerouslySetInnerHTML={{ __html: tier.content1 }}></p>
              <p dangerouslySetInnerHTML={{ __html: tier.content2 }}></p>
            </div>
            {(!isMobile || isShowMore) && (
              <>
                {
                  tier.keypoints.length > 0 && (
                    <ul className='w-full text-[16px]'>
                      {tier.keypoints.map((point, index) => {
                        return (
                          <li key={index} className='flex items-center gap-2'>
                            <RxDot className='text-[#E4B456]' size={24} />
                            <span>{point}</span>
                          </li>
                        )
                      })}
                    </ul>
                  )
                }
              </>
            )}
            {isMobile && !isShowMore && (
              <div className='w-full text-[#727272] flex items-center justify-center flex-col border-t border-t-[#727272]'>
                <h4 className='pt-2' onClick={onClickShowMore}>show more</h4>
                <BsChevronDown onClick={onClickShowMore} />
              </div>
            )}
            <div className='flex justify-center items-center'>
              <button
                onClick={() => onSelectPlan(tier.title)}
                className='w-[130px] flex px-5 smsize:w-full cursor-pointer h-[40px] justify-center items-center rounded-full border  border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-[18px] text-base font-medium text-white hover:bg-white hover:text-fitzba-bright-gold-static uppercase'>
                {tier.button}
              </button>
            </div>
          </div>

        </div>
      </div>
    </div >
  )
}
