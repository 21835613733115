import React, { useEffect, useState, useRef } from 'react'
// import { IoIosSearch } from 'react-icons/io'
import StoreFilter from './StoreFilter'
// import DealProducts from './components/DealProducts'
// import NewItemsProducts from './components/NewItemsProducts'
import AllProducts from './components/AllProducts'
import { setItemsFilter, setSearchFilter } from 'reducers/contentReducer'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
// import { Input } from 'antd'
// import StoreProfileBrand from './components/StoreProfileBrand'
// import StoreProfileCategory from './components/StoreProfileCategory'
import FilterSideSelectedMobile from './components/FilterSideSelectedMobile'
import StoreCategories from './components/StoreCategories'
import StoreBrands from './components/StoreBrands'

// import GiftCards from './components/GiftCards'
const tabs = [
    'All Items',
    'New Items',
    'Hot Deals',
]
// const TestCategories = [
//     {
//         "id": 173524553,
//         "name": "E-Bikes",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578415994.png",
//     },
//     {
//         "id": 173504335,
//         "name": "Bikes",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578422570.png",
//     },
//     {
//         "id": 173518590,
//         "name": "Sale",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578427148.png",
//     },
//     {
//         "id": 173518589,
//         "name": "Clothing",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4562907643.jpg",
//     },
//     {
//         "id": 173518588,
//         "name": "Accessories",
//         "image": "https://d2j6dbq0eux0bg.cloudfront.net/images/108843109/4578484234.png",
//     }
// ]
export default function StoreMain({
    isMobile,
    business,
    // location
}) {

    const [width, setWidth] = useState(1200) // State to store the width
    const productRef = useRef(null)

    const dispatch = useDispatch()
    const storeProfileFilter = useSelector(state => state.content.itemsFilter)
    // console.log('storeProfileFilter', storeProfileFilter)
    const [products, setProducts] = React.useState(null)
    // const storeProfileList = useSelector(state => state.content.storeProfileList)
    // const [input, setInput] = React.useState(storeProfileFilter.title || '')
    // const [isOpenBrand, setIsOpenBrand] = React.useState(false)
    // const [isOpenCategory, setIsOpenCategory] = React.useState(false)
    const [tab, setTab] = React.useState('All Items')
    // const [isLoading, setIsLoading] = React.useState(true)

    useEffect(() => {
        // Function to update the width
        const updateWidth = () => {
            if (productRef.current) {
                console.log(productRef.current.offsetWidth)
                setWidth(productRef.current.offsetWidth) // Get the width using offsetWidth
            }
        }
        // Update the width when the component mounts
        updateWidth()
        // Optionally, update the width when the window is resized
        window.addEventListener('resize', updateWidth)
        // Cleanup the event listener on unmount
        return () => window.removeEventListener('resize', updateWidth)
    }, [productRef])

    // useEffect(() => {
    //     if (!isLoading)
    //         return
    //     let tmpProfileFilter = localStorage.getItem('storeProfileFilter')
    //     if (tmpProfileFilter) {
    //         tmpProfileFilter = JSON.parse(tmpProfileFilter)
    //         dispatch(setStoreProfileFilter(tmpProfileFilter))
    //         localStorage.removeItem('storeProfileFilter')
    //         dispatch(setStoreProfileFilter(tmpProfileFilter))
    //     }
    //     setIsLoading(false)
    // }, [dispatch, storeProfileFilter, isLoading])

    // const handleChange = debounce((value) => {
    //     console.log('onSearch', value)
    //     // dispatch(setItemsFilter({
    //     //     ...storeProfileFilter,
    //     //     title: value
    //     // }))
    //     dispatch(setSearchFilter({
    //         type: 'title',
    //         value: value
    //     }))
    // }, 600)
    console.log('storeProfileFilter', storeProfileFilter)
    let isSearch = false
    if (storeProfileFilter.title ||
        storeProfileFilter.brands.length > 0 ||
        storeProfileFilter.categories.length > 0 ||
        storeProfileFilter.condition.length > 0 ||
        (storeProfileFilter.priceMin !== -1 || storeProfileFilter.priceMax !== -1) ||
        storeProfileFilter.deliveryTypes.length > 0 ||
        storeProfileFilter.isOnSale
    ) {
        isSearch = true
    }

    console.log('business222', business)

    // const onClickBrandItem = (brand_id) => {
    //     // dispatch(setItemsFilter({
    //     //     ...storeProfileFilter,
    //     //     brands: [brand_id]
    //     // }))
    //     dispatch(setSearchFilter({
    //         type: 'brands',
    //         value: [brand_id]
    //     }))
    //     // setIsOpenBrand(false)
    // }

    // const onClickCategoryItem = (id) => {
    //     console.log('id', id)
    //     // dispatch(setItemsFilter({
    //     //     ...storeProfileFilter,
    //     //     categories: [id]
    //     // }))
    //     dispatch(setSearchFilter({
    //         type: 'categories',
    //         value: [id]
    //     }))
    //     // setIsOpenCategory(false)
    // }

    const setFilterBrandCheckOne = (value) => {
        console.log('setFilterBrandCheckOne value', value)
        dispatch(setItemsFilter({
            ...storeProfileFilter,
            brands: value.checked
                ? [...storeProfileFilter.brands, value._id]
                : storeProfileFilter.brands.filter(item => item !== value._id)
        }))
    }
    const setFilterCategoryCheckOne = (value) => {
        console.log('setFilterCategoryCheckOne value', value)
        const isExist = storeProfileFilter.categories.find(item => item === value._id)
        dispatch(setItemsFilter({
            ...storeProfileFilter,
            categories: isExist
                ? storeProfileFilter.categories.filter(item => item !== value._id)
                : [...storeProfileFilter.categories, value._id]
        }))
    }

    return (
        <>
            <div className='w-full flex h-full bg-white smsize:flex-col'>
                {!isMobile &&
                    <StoreFilter business={business} isMobile={isMobile} setFilterBrandCheckOne={setFilterBrandCheckOne} />
                }
                <div className='flex flex-col w-full'>
                    {!isMobile &&
                        <FilterSideSelectedMobile
                            brands={business?.brands}
                            categories={business?.categories}
                            storeCategories={business?.storeCategories}
                            storeProfileFilter={storeProfileFilter}
                            isMobile={isMobile}
                            dispatch={dispatch}
                        />
                    }
                    <div ref={productRef} id='store-main-product' className='flex-2 w-full py-[30px] px-[30px] flex flex-col items-start justify-start gap-3 smsize:p-5'>
                        <StoreCategories
                            business={business}
                            isMobile={isMobile}
                            width={width}
                            setFilterCategoryCheckOne={setFilterCategoryCheckOne}
                        />
                        <StoreBrands
                            business={business}
                            isMobile={isMobile}
                            width={width}
                            setFilterBrandCheckOne={setFilterBrandCheckOne}
                        />
                        {/* <div className='text-left flex flex-col gap-3'>
                    <h2 className='text-[24px] font-medium'>Brands</h2>
                    <div className='flex flex-wrap gap-3 leading-5'>
                        <div className='flex items-center gap-3 rounded-lg border py-2 px-3'>
                            <span className='text-[14px]'>Brand1</span>
                        </div>
                        <div className='flex items-center gap-3 rounded-lg border py-2 px-3'>
                            <span className='text-[14px]'>Brand2</span>
                        </div>
                        <div className='flex items-center gap-3 rounded-lg border py-2 px-3'>
                            <span className='text-[14px]'>Brand3</span>
                        </div>
                    </div>
                </div> */}

                        <div className='w-full flex items-start justify-start pt-2 gap-14 text-[16px] font-semibold'>
                            {tabs.map((t, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        console.log('tab', t)
                                        setTab(t)
                                    }}
                                    className={`cursor-pointer leading-9 ${tab === t ? 'border-b-[3px] border-[#E4B456]' : ''}`}>
                                    {t}
                                </div>
                            ))}
                        </div>
                        {/* <GiftCards business={business} isMobile={isMobile} /> */}
                        <div className='w-full flex flex-col items-start'>
                            {/* {tab === 'Hot Deals' &&
                        <DealProducts business={business} width={width} isMobile={isMobile} />
                    }
                    {tab === 'New Items' &&
                        <NewItemsProducts business={business} width={width} isMobile={isMobile} />
                    } */}
                            {/* {tab === 'All Items' && */}
                            <AllProducts
                                business={business}
                                isMobile={isMobile}
                                isSearch={isSearch}
                                products={products}
                                setProducts={setProducts}
                                tab={tab}
                            />
                            {/* } */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
