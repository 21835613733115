import React, { useEffect, useState } from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import FilterSideCategoryList from './FilterSideCategoryList'
import { CiViewList } from "react-icons/ci"

export default function MobileTopFilterCategory() {
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const isMobile = useSelector(state => state.content.isMobile)

    return (
        <>
            <MobileTopFilterItem
                selectedable={true}
                firstIcon={<CiViewList size={16} />}
                text={itemsFilter?.category?.length > 0 ? `Category (${itemsFilter?.category?.length})` : 'Category'}
                onClick={() => {
                    setOpen(true)
                }}
                isSelected={itemsFilter?.category?.length > 0}
            />
            <Drawer
                title={''}
                closeIcon={''}
                className='filter-mobile-drawer'
                placement={'bottom'}
                onClose={() => setOpen(false)}
                open={open}
                key={'mobile-filter-price'}
                height={'80%'}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                extra={
                    <div className='flex justify-end'>
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                }
                // extra={<NavZearchBar />}
                style={{
                    // height: '100vh',
                    textAlign: 'left',
                    overflowY: 'auto',
                    paddingBottom: 20,
                    // backgroundColor: '#202020',
                    borderRadius: '10px 10px 0 0',
                }}
                footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center text-white'
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    View results
                </button>}
            >
                <div className='flex flex-col gap-3 justify-between h-full'>
                    <FilterSideCategoryList
                        dispatch={dispatch}
                        itemsFilter={itemsFilter}
                        isMobile={isMobile}
                        isDrawer={true}
                        color={'#202020'}
                        bgColor={'#fff'}
                    />
                </div>
            </Drawer>
        </>
    )
}
