/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 *  The Header for the app, containing the logo,
 *  dropdown button and location. once the user login,
 *  their avatar will also display here
 *
 * @author Yang Ming, James Nguyen
 * @version May 09, 2023
 */

import React, { Suspense } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './Header.css'
import HamburgerDropdown from './HamburgerDropdown'
import CategoriesHeaderNew from '../pages/home/components/CategoriesHeaderNew'
import Location from './Location'
import NavZearchBar from './NavZearchBar'
// import { Avatar, Dropdown } from 'antd'
// import {
//   AiOutlineUser,
//   AiOutlineLogin,
//   AiOutlineShop,
//   AiOutlineBarcode,
// } from 'react-icons/ai'
// import { logout } from '@services/api.service'
import { useSelector, useDispatch } from 'react-redux'
// import { logout } from '../reducers/userReducer'
import { initItemsFilter } from '../reducers/contentReducer'
// import { logout as logoutAPI } from '@services/api.service'
// import CategoriesHeader from '../pages/home/components/CategoriesHeader'
import HeaderShoppingCartIcon from './HeaderShoppingCartIcon'
// import { setBackToRoute } from '@reducers/tempReducer'
// import { IoCaretDownSharp } from "react-icons/io5"
// import DropdownMenuItem from './DropdownMenuItem'
// import LandingLocationNew from '../pages/home/components/LandingLocationNew'
import { getOSName } from '@utils/Helper'
import StoreCategoriesHeader from 'pages/store/components/StoreCategoriesHeader'
// const US_STATES = usStates.map(item => ({ label: item.code, value: item.name }))

export default function HeaderMobile() {
  // Redux state manager to manage user info
  const dispatch = useDispatch()
  const userinfo = useSelector(state => state.user.userinfo)
  // const isBusiness = useSelector(state => state.user.isBusiness)
  const location = useSelector(state => state.user.location)
  const storeProfileId = useSelector(state => state.content.storeProfileId)
  // const isMobile = useSelector(state => state.content.isMobile)
  // const [isShowPWA, setIsShowPWA] = React.useState(false)
  const osName = getOSName()

  // const navigate = useNavigate()
  const { pathname } = useLocation()

  if (pathname === '/register' || pathname === '/login') {
    return null
  }

  //async function to handle user logout
  // async function onClickLogout() {
  //   await logoutAPI()
  //   dispatch(logout())
  //   navigate('/')
  // }
  // the content of the user dropdown

  const onClickLogo = () => {
    window.scrollTo(0, 0)
    dispatch(initItemsFilter())
  }

  // const onClickLogin = () => {
  //   console.log('login', pathname)
  //   // get current url
  //   const currentUrl = window.location.href
  //   console.log('onClickLogin', currentUrl)
  //   // if the current url is not the login page, save the url to local storage
  //   if (currentUrl.indexOf('/login') === -1) {
  //     localStorage.setItem('loginUrl', currentUrl)
  //   }
  //   dispatch(setBackToRoute(pathname))
  // }

  return (
    <div className={`sticky top-0 z-[999] w-full`} id='header'>
      {/* {pathname === '/' && (
        <div className='bg-[#ffedbe] h-[40px] flex items-center justify-center gap-2 whitespace-nowrap' style={{
          backgroundImage: 'url(/images/top-giveway-bg.png)',
        }}>
          <h4 className='text-[12px] font-bold'>WIN a $500 SHOPPING SPREE</h4>
          <Link to='/register?ref=contest' className='text-[12px] bg-white rounded-full border-2 border-[#202020] px-2 whitespace-nowrap'>JOIN FOR FREE</Link>
        </div>
      )} */}
      {/* {pathname === '/' && (
        <div>
          <Link to='/register?ref=contest'>
            <img src="/gift-guide/header-mobile.png" alt="Header" className='w-full object-cover' />
          </Link>
        </div>
      )} */}
      <header className={`flex w-full bg-fitzba-off-black-static px-3 pb-2 ${osName === 'IOS Webview' ? 'pt-2' : 'pt-2'} text-fitzba-white-static justify-between items-center flex-col gap-2`}>
        <div className='w-full flex gap-3 items-center justify-between'>
          <div className='flex items-center justify-center gap-3'>
            <HamburgerDropdown
              userinfo={userinfo}
            />
            <div className='flex flex-row items-center gap-1 xs:gap-4'>
              <Link to='/' onClick={onClickLogo}>
                <img
                  src={'/fitzba-WORD-WHITE.png'}
                  alt='Fitzba'
                  className='z-[50] w-[80px]'
                />
              </Link>
              <span>
                {location?.country === 'US' ?
                  <img src='/images/flag_us.png' alt='Canada Flag' className='xs:pt-[20px]' /> :
                  <img src='/images/flag_ca.png' alt='Canada Flag' className='xs:pt-[20px]' />
                }
              </span>
            </div>
            <Location />
          </div>
          <HeaderShoppingCartIcon />
        </div>
        <div className='w-full'>
          <NavZearchBar />
        </div>
      </header>
      {pathname === '/' && (
        <CategoriesHeaderNew />
      )}
      {storeProfileId && (
        <StoreCategoriesHeader storeProfileId={storeProfileId}/>
      )}
      {/* {pathname === '/' && (
        <LandingLocationNew />
      )} */}
    </div>
  )
}
