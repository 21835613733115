import React from 'react'
import DropDown from './DropDown'

export default function Header({
    selectItems,
    index,
    setIndex,
}) {
    return (
        <div className='flex py-3 w-full items-center justify-between bg-[#222222] px-5 text-white'>
            <a href='https://www.fitzba.com' rel="noreferrer">
                <img src='/logo-white.png' className='w-[120px] cursor-pointer smsize:w-[100px]' alt='Fitzba.com' />
            </a>
            <div className='flex gap-3 items-center justify-center'>
                <div className='smsize:text-[12px] uppercase'>Winter Shopping Guide</div>
                <DropDown selectItems={selectItems} index={index} setIndex={setIndex} />
            </div>
        </div>
    )
}
