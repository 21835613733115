
import React from 'react'
import RecommendedCard from './RecommendedCard'
import { getRecommendedList } from '@services/api.recommended.service'
import { useMediaQuery } from 'react-responsive'
import Loading from '@components/Loading'
import { useQuery } from '@tanstack/react-query'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import RecommendedProducts from './RecommendedProducts'
import RecommendedCardNew from './RecommendedCardNew'
import { Link } from 'react-router-dom'
import { IoMdArrowDropright } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

export default function RecommendedNew() {

  const navigate = useNavigate()
  const recommendedRef = React.useRef(null)
  const [selectedId, setSelectedId] = React.useState(null)

  const {
    isPending,
    error,
    data,
  } = useQuery({
    queryKey: ['recommendedList'],
    queryFn: getRecommendedList,
  })

  //If the query is pending, return a loading component
  if (isPending) return <Loading />
  // If there is an error, return an error message
  if (error) return 'Recommend Section: An error has occurred: ' + error.message

  const recommendedList = data?.data || []
  if (!selectedId && recommendedList.length > 0) {
    console.log('recommendedList', recommendedList[0])
    setSelectedId(recommendedList[0]._id)
  }

  const onClickRecommended = (id) => {
    console.log('onClickRecommended', id)
    const item = recommendedList.find(item => item._id === id)
    if (!item) return
    if (item.type === 'keyword') {
      window.location.href = `/search?keyword=${item.target}`
      return
    } else {
      navigate(`/collections?id=${item._id}`)
    }
    setSelectedId(id)
  }

  // Function to handle click on scroll button
  const onClickScrollBtn = step => {
    if (!recommendedRef.current)
      return
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      recommendedRef.current.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= 1020) {
        clearInterval(slideTimer)
      }
    }, 50)
  }

  return (
    <div className='w-full bg-[#F8F8F8] py-5 mt-5'>
      <div className='m-auto w-[1280px] p-5 flex flex-col gap-5 relative smsize:w-full smsize:p-2'>
        {/* image lists: recommend-1.png */}
        <div className='w-full text-left flex gap-5 items-center overflow-x-hidden scroll-wrap smsize:overflow-x-auto' ref={recommendedRef}>
          <span className='scroll-left smsize:hidden' style={{ top: '165px' }} onClick={() => onClickScrollBtn(-50)}><AiOutlineLeft /></span>
          <span className='scroll-right smsize:hidden' style={{ top: '165px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
          {recommendedList?.slice(0, 5)?.map((item, index) => (
            <RecommendedCardNew
              key={index}
              item={item}
              selectedId={selectedId}
              onClickRecommended={onClickRecommended} />
          ))}
        </div>
        {/* {selectedId && (
          <RecommendedProducts
            recommendedList={recommendedList}
            selectedId={selectedId}
          />
        )} */}
        <div className='w-full flex items-center justify-center smsize:flex-col smsize:gap-4'>
          <div className='bg-[#202020] w-fit rounded-full text-[16px] smsize:text-[13px] font-light py-5 px-10 smsize:px-5 smsize:py-3 flex justify-center items-center text-white gap-4 text-center'>
            <h3 className='font-lexend tracking-[0.3rem]'>Shop more curated collections</h3>
            <Link to='/collections' className='rounded-full bg-[#E4B456] text-white py-2 px-9 whitespace-nowrap flex items-center justify-center smsize:hidden'>shop now <IoMdArrowDropright /></Link>
          </div>
          <Link to='/collections' className='hidden rounded-full bg-[#E4B456] text-white py-2 px-8 whitespace-nowrap smsize:flex items-center justify-center'>shop now <IoMdArrowDropright /></Link>
        </div>
      </div>
    </div>
  )
}
